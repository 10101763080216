<template>
  <v-data-table
    class="elevation-1"
    :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
    :headers="headers"
    item-key="id"
    :items="this.pagos.filter(filtrado => filtrado.cursada.id === this.cursada)"
    :loading="loadingStatus"
    loading-text="Cargando...por favor aguarde"
    :search="search">
    <template v-slot:top>
      <v-toolbar
        flat
        height="150px">
        <v-row class="flex flex-space-between">
          <v-col
            cols="12"
            lg="6">
            <v-toolbar-title>Lista de Pagos por Cursadas</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical></v-divider>
            <v-spacer></v-spacer>
          </v-col>
          <v-col
            cols="12"
            lg="6">
            <v-select
              v-model="cursada"
              autocomplete
              bottom
              item-text="courses.name"
              item-value="id"
              :items="cursadas.filter(courses => courses.lectivo.ano === new Date().toISOString().substr(0, 4))"
              label="Elige una cursada"
              style="width:300px;"></v-select>
          </v-col>
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Datos del Pago</span>
              </v-card-title>
              <v-card-text class="mt-7">
                <v-container>

                  <p><strong>Curso: </strong>{{courseName(editedItem.cursada_id)}}</p>
                  <p><strong>Alumno: </strong>{{editedItem.user.name}}</p>
                  <p><strong>Subtotal: </strong>{{editedItem.subtotal}}</p>
                  <p><strong>Descuento: </strong>{{(1-editedItem.descuento).toFixed(2) * 100}} %</p>
                  <p><strong>Total: </strong>{{editedItem.total}}</p>


                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.state="{ item }">
      <h6
        v-if="item.estado === '0'"
        class="yellow darken-1 grey--text text--darken-1 text-center py-1 rounded"> Sin pagar</h6>
      <h6
        v-if="item.estado === '1'"
        class="green darken-1 grey--text text--lighten-5 text-center py-1 rounded"> Pagado</h6>
      <h6
        v-if="item.estado === '2'"
        class="red darken-1 grey--text text--lighten-5 text-center py-1 rounded"> Anulado</h6>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class="mr-2"
        small
        @click="buy(item)">
        mdi-check-bold
      </v-icon>
      <v-icon
        class="mr-2"
        small
        @click="cancel(item)">
        mdi-close-thick
      </v-icon>
      <v-icon
        class="mr-2"
        small
        @click="editItem(item)">
        mdi-eye
      </v-icon>
    </template>
    <template slot="no-data">
      <p>Por el momento no hay pagos realizados</p>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    data: () => ({
      cursada: '',
      filterInscriptions: [],
      valid: true,
      expanded: [],
      singleExpand: true,
      search: '',
      date: null,
      menu: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Alumnno', value: 'user.name' },
        { text: 'Mes', value: 'concepto' },
        { text: 'Total', value: 'total' },
        { text: 'Estado', value: 'state', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],

      editedIndex: -1,
      editedItem: {
        id: '',
        cursada_id:'',
        cursada: {
          id:'',
          price:'',
        },
        user_id:'',
        user: {
          id:'',
          name:'',
        },
        concepto:'',
        descuento:'',
        estado:'',
        subtotal:'',
        total:'',
      },
      defaultItem: {
        id: '',
        cursada_id:'',
        cursada: {
          id:'',
          price:'',
        },
        user_id:'',
        user: {
          id:'',
          name:'',
        },
        concepto:'',
        descuento:'',
        estado:'',
        subtotal:'',
        total:'',
      },
      nameRules: [
        value => !!value || 'Campo requerido',
        value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 40) || 'Máximo 40 caracter',
      ],
      descriptionRules: [
        value => !!value || 'Campo requerido',
        value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 200) || 'Máximo 200 caracter',
      ],
    }),

    computed: {

      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      users() {
        return this.$store.getters.getUsers
      },
      pagos() {
        return this.$store.getters.getPagos
      },
      cursadas() {
        return this.$store.getters.getCursadas
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Pago' : 'Editar Pago'
      },
      cursadasCurrent() {
        let current = this.cursadas.filter(courses => courses.lectivo.ano === new Date().toISOString().substr(0, 4) );
        return current;
      },

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.$store.dispatch('fetchUsers')
      this.$store.dispatch('fetchCursadas')
      this.$store.dispatch('fetchPagos')
    },

    methods: {
      async buy(item) {
        let res = await this.$confirm('¿Quiere abonar una Cuota?', { title: 'Pago' })
        if (res) {
          this.editedItem = Object.assign({}, item)
          this.editedItem.estado = '1'
          this.$store.dispatch('updatePago', this.editedItem)
        }
      },
      async cancel(item) {
        let res = await this.$confirm('¿Quiere anular una Cuota?', { title: 'Pago' })
        if (res) {
          this.editedItem = Object.assign({}, item)
          this.editedItem.estado = '2'
          this.$store.dispatch('updatePago', this.editedItem)
        }
      },

      state(item) {
        if(item === '0') return 'Sin pagar'
        else if(item === '1') return 'Pagado'
        else if(item === '2') return 'Anulado'
      },
      customFilter (item, queryText, itemText) {
        const textOne = item.name.toLowerCase()
        const textTwo = item.dni.toString()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
      editItem (item) {
        this.editedIndex = this.pagos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      courseName(id) {
        let curso = this.cursadas.filter(filtrado => filtrado.id === id)
        if(curso.length !== 0) return curso[0].courses.name
      }
    },
  }
</script>

<style>

</style>