<template>
  <v-container>
    <v-row
      align="center"
      justify="space-around">
      <v-btn
        class="my-10"
        color="primary"
        depressed
        :loading="loadingStatus"
        @click="generate()">Generar cuotas del mes {{ this.mes }}</v-btn>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      data: {
        cron_token: process.env.VUE_APP_CRON_TOKEN,
      },
      dialogDelete: false,
      mes: new  Date().getMonth() + 1
    }),
    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
    },
    methods: {
      async generate (item) {
        let res = await this.$confirm('¿Quieres generar las cuotas?', { title: 'Cuotas' })
        if (res) {
          this.$store.dispatch('createCuotas', this.data)
        }
      },
    }
  }
</script>

<style>

</style>