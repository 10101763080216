<template>
  <div class="container mb-16">
    <a id="contactanos" />
    <!--Header /-->
    <v-divider class="mx15" />
    <h1 class="tittle pr-15 text-right">
      Contactanos
    </h1>

    <div class="row mt-5">
      <div class="col-md-12 col-lg-4 mb-5 mt-lg-0">
        <div class="datos h-100">
          <span>
            <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
            Av. 9 de Julio 210
          </span>
          <span>
            <font-awesome-icon :icon="['far', 'envelope']" />
            administration@institutolya.com.ar
          </span>
          <span>
            <font-awesome-icon :icon="['fas', 'mobile-alt']" />
            362 4098900
          </span>
        </div>
      </div>

      <div class="col-md-12 col-lg-1 mb-5 mt-lg-0">
        <div class="redes">
          <a
            v-for="(red,item) in url"
            :key="item"
            class="mt-10"
            :href="red.item"
            target="_blanck"><font-awesome-icon :icon="['fab', red.icon]" /></a>
        </div>
      </div>

      <div class="col-md-12 col-lg-7 mb-5">
        <v-form
          ref="contactForm"
          class="form"
          @submit.prevent="onSubmit">
          <v-text-field
            v-model="contact.name"
            class="size-form"
            :counter="100"
            label="Nombre completo"
            required
            :rules="nameRules" />
          <v-text-field
            v-model="contact.motivo"
            class="size-form"
            :counter="50"
            label="Motivo"
            required
            :rules="motiveRules" />
          <v-text-field
            v-model="contact.email"
            class="size-form"
            :counter="100"
            label="E-mail"
            required
            :rules="emailRules" />
          <v-textarea
            v-model="contact.text"
            class="size-form"
            :counter="400"
            label="Su consulta"
            name="text"
            :rules="textRules"
            value="" />
          <v-row class="align-right">
            <v-btn
              class="mt-5"
              color="secondary"
              rounded
              variant="dark"
              x-large
              @click="clear">
              clear
            </v-btn>
            <v-btn
              class="mt-5 ml-5"
              color="primary"
              :loading="loadingStatus"
              rounded
              type="submit"
              variant="dark"
              x-large>
              Enviar
            </v-btn>
          </v-row>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data: () => ({
      contact:{
        name:'',
        motivo:'',
        email:'',
        text:''
      },
      nameRules: [
        (v) => !!v || "Este campo es requerido",
        v => (v && v.length <= 100) || "No puede pasar de 100 dígitos"
      ],
      emailRules: [
        (v) => !!v || "Email es requerido",
        (v) => /.+@.+/.test(v) || "El Email debe ser valido"
      ],
      motiveRules: [
        (v) => !!v || "El motívo es requerido",
        v => (v && v.length <= 50) || "No puede pasar de 50 dígitos"
      ],
      textRules: [
        (v) => !!v || "El texto de la consulta es requerido",
        v => (v && v.length <= 400) || "No puede pasar de 400 dígitos"
      ],
      url: [
        {
          icon: "whatsapp-square",
          item: "https://api.whatsapp.com/send?phone=543624098900"
        },
        {
          icon: "facebook",
          item: "https://www.facebook.com/LyAinstitutodelenguasyarte"
        },
        {
          icon: "instagram-square",
          item: "https://www.instagram.com/lyainstituto"
        },
      ],
    }),
    methods: {
      clear() {
        this.$refs.contactForm.reset()
      },
      onSubmit() {
        if (this.$refs.contactForm.validate()) {
          this.$store.dispatch('sendContact', this.contact)
        } else {
          this.$toastErrors('Hay errores en su formulario')
        }
      },
    },
    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
    }
  };
</script>
<style lang="scss">
  .datos {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    border-right: 2px solid $gris-medio;
    height: 100%;
    color:gray;

    span {
      margin: 1rem 4rem 1rem 0;
      // font-weight: bold;
      svg {
        margin-right: .5rem;
      }
    }

    @media screen and (max-width: 991px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      border-right: none;
      // border-bottom: 2px solid $gris-medio;

      span {
        margin: 0 2rem 2rem;
      }
    }

    @media screen and (max-width: 676px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  .redes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // border-left: 2px solid $gris-medio;
    height: 100%;

    a {
      margin: .5rem 4rem;
      font-size: 2rem;
      color: gray;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.instagram, &.twitter {
        background-color: gray;
        border-radius: 50%;
        font-size: 1.4rem;
        color: white;

        &:hover {
          background-color: $primary;
        }
      }
    }

     @media screen and (max-width: 991px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-left: none;
      border-top: 2px solid $gris-medio;

      a {
        margin: 2rem 2rem 0;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-control {
      margin: .5rem 0;
    }
  }
  .size-form {
        width: 70% !important;
  }
</style>
