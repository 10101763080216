<template>
  <v-container
    fill-height
    fluid
    text-center>
    <!-- <v-row
      justify="end">
      <v-card-text
        class="text-center "
        style="width:300px; height:50px;">
        <p
          class="rounded px-2 py-2 red lighten-4 text-caption
                   text-justify">
          <strong>Su email no se encuentra verificado</strong><br>
          Al momento de ser registrado usted ha recibido un email para verificar
          su correo, si necesita que se le envíe otro presione aqui:
        </p>
        <v-row justify="center">
          <v-btn
            class="primary"
            width="90%"
            @click="goTo( servicio.href )">
            Reenviar correo
          </v-btn>
        </v-row>
      </v-card-text>
    </v-row> -->
    <v-row
      align="center"
      justify="center">
      <img
        alt=""
        class="mt-6"
        justify-center
        src="../../assets/logo.svg"
        width="50%">
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({

    }),
    computed: {
      // user() {
      //   return this.$store.getters.getUser
      // }
    }
  }
</script>

<style>

</style>