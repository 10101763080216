<template>
  <div class="banner">
    <div class="texto-banner">
      <v-img
        class="img-card "
        :src="require('../assets/logo_gris.svg')" />
      <div class="img-float">
        <v-img
          class="img-text ingreso-izquierdo"
          :src="require('../assets/opcion.svg')" />
        <v-img
          class="img-text  float-right ingreso-derecho"
          :src="require('../assets/diferente.svg')" />
      </div>
      <div
        class="btn-card">
        <v-btn
          link
          rounded
          style="text-decoration:none"
          :to=" {name: 'Courses' }">Ver Cursos</v-btn>
      </div>
    </div>
    <div
      class="d-flex flex-space-around footer-img ">
      <v-row
        align="center"
        class="text-center"
        justify="center">
        <div
          style="min-width:400px; padding:5px">
          <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
          Av. 9 de Julio 210
        </div>
        <div

          style="min-width:400px">
          <font-awesome-icon :icon="['far', 'envelope']" />
          administration@institutolya.com.ar
        </div>
        <div

          style="min-width:400px">
          <font-awesome-icon :icon="['fas', 'mobile-alt']" />
          3624-098900
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {

      };
    },
  };
</script>

  <style lang="scss"
         scoped>
        @media (max-width: 550px) {
           .texto-banner {
             left: 0px !important;
             width: 100%;
           }
           .v-image {
              width: 20px  !important;
           }

        }
        @media (max-width: 900px) {
           .footer-img {
              padding: 15px;
              height: 100px;
              margin-top: calc(100vh - 200px) !important;
           }
        }

         .ingreso-izquierdo {
          animation: lightSpeedInLeft;
          animation-duration: 3s;
         }
         .ingreso-derecho {
          animation: rotateInDownRight;
          animation-duration: 3s;
         }
         .btn-card {
           display: flex;
           justify-content: center;
           margin-top: 20px;
           button {
             width: 150px;
             padding: 15px;
           }
         }
         .img-float {
           display: flex;
           flex-wrap: wrap;
           justify-content: space-between;
         }
         .img-text {
           width: 190px;

         }
         .img-card {
           min-width: 150px;
           width: 150px;
           margin-left: auto;
           margin-right: auto;
           animation: rubberBand;
           animation-duration: 3s;
         }
         .banner {
           display: flex;
           align-items: center;
         }
         .texto-banner {
           position: absolute;
           left: 5%;
           background-color: rgba(114, 109, 87, 0.2);
           padding: 30px;
           min-width: 400px;
           border-radius: 15px;
         }
         .banner {
           width: 100%;
           height: calc(100vh - 90px);
           background-color: orange;
           background: url('~@/assets/ninos.png') top right / cover no-repeat;
         }
         .card-banner {
           display: flex;
           justify-content: center;
           margin-left: 50px;
           padding:10px;
           background-color: rgba(0,0,0,0.1) !important;
           position: absolute;
           bottom: 20px;

         }
         .footer-img {
           width: 100%;
           min-height: 60px;
           background-color: rgba(245, 222, 121, 0.2);
           position:absolute;
           margin-top: calc(100vh - 150px);
         }
         .banner {
           img {
             height: 100vh;
             width: 100vh;
           }
         }

  </style>
