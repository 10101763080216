<template>
  <v-container>
    <sidebarAlumno />
  </v-container>
</template>

<script>

  import SidebarAlumno from '@/components/alumno/SidebarAlumno'
  import { mapGetters } from 'vuex'
  export default {
    components: { SidebarAlumno },
    computed: {
      ...mapGetters([
        'isAdmin',
        'getUser',
      ])
    }
  }
</script>

<style lang="scss">

</style>
