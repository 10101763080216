<template>
  <v-container>
    <!-- AÑO LECTIVO -->
    <v-row class="mx-15">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        min-width="290px"
        :nudge-right="40"
        offset-y
        transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="data.ano"
            class="mt-10"
            label="Año Lectivo"
            prepend-icon="event"
            readonly
            v-on="on">
          </v-text-field>
        </template>
        <v-date-picker
          ref="picker"
          v-model="date"
          :max=" this.max"
          :min=" this.min"
          no-title
          reactive
          @input="save">
        </v-date-picker>
      </v-menu>
      <!-- INICIO -->
      <v-dialog
        ref="dialogInicio"
        v-model="modalInicio"
        persistent
        :return-value.sync="data.inicio"
        width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.inicio"
            class="mt-lg-10 mt-md-10 mt-sm-10"
            label="Inicio"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"></v-text-field>
        </template>
        <v-date-picker
          v-model="data.inicio"
          locale="es"
          scrollable>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="modalInicio = false">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="$refs.dialogInicio.save(data.inicio)">
            Guardar
          </v-btn>
        </v-date-picker>
      </v-dialog>
      <!-- FIN -->
      <v-dialog
        ref="dialogFinal"
        v-model="modalFinal"
        persistent
        :return-value.sync="data.final"
        width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.final"
            class="mt-lg-10 mt-md-10 mt-sm-10"
            label="Final"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"></v-text-field>
        </template>
        <v-date-picker
          v-model="data.final"
          locale="es"
          scrollable>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="modalFinal = false">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="$refs.dialogFinal.save(data.final)">
            Guardar
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-row>
    <!-- BOTON -->
    <v-card
      class="d-flex flex-row-reverse my-4 mr-15"
      flat
      tile>
      <v-btn
        color="primary"
        @click="crearLectivo">Enviar
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
  import moment from 'moment';
  export default {
    data: () => ({
      anoRules: [
        value => !!value || 'Campo requerido',
        value => (value && value.length >= 5) || 'Tiene que tener formato 2021',
        value => (value && value.length <= 3) || 'Tiene que tener formato 2021',
      ],
      data: {
        ano: '',
        inicio: '',
        final:'',
      },
      date: new Date().toISOString().substr(0, 4),
      menu: false,
      today: '',
      min:'',
      max:'',
      modalInicio: false,
      modalFinal: false,
    }),
    watch: {
      menu (val) {
        val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
      }
    },
    created() {
      this.today = new Date()
      this.min = this.sumarYears(this.today, - 3 ).toISOString().substr(0, 4)
      this.max = this.sumarYears(this.today, + 6 ).toISOString().substr(0, 4)
    },
    methods: {
      save (date) {
        this.$refs.menu.save(date);
        this.data.ano = date.substring(0,4)
        this.$refs.picker.activePicker = 'YEAR'
        this.menu = false;
      },
      sumarYears(fecha, dias){
        fecha.setFullYear(fecha.getFullYear() + dias)
        return fecha;
      },
      crearLectivo() {
        this.$store.dispatch('createLectivo', this.data)
      },
    }
  }
</script>

<style>

</style>