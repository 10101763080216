<template>
  <v-container>
    <v-main>
      <Groups v-if="!this.$route.params.id" />
      <ListCourses v-else />
    </v-main>
  </v-container>
</template>

<script>
  import Groups from "@/components/Groups";
  import ListCourses from "@/components/ListCourses";
  export default {
    components: {
      Groups,
      ListCourses,
    },

  }
</script>

<style>

</style>