<template>
  <v-container>
    <v-card flat>
      <div class="d-flex flex-space-around px-16">
        <v-img
          alt="John"
          class="mt-5 ml-5"
          :src="require('../assets/clases.svg')"
          width="100" />
        <v-card-text class="text-left mt-10 pl-8 title "> Instituciones Educativas</v-card-text>
      </div>
      <v-card-text class="text-center  px-8">
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify px-16">
          Nosotros sabemos lo difícil que se puede transformar la organización de
          un departamento de inglés en una institución educativa, es por eso que nos ofrecemos a ayudarte, a aportar nuestro conocimiento.
        </p>
        <p class="title text-left px-16">Desde Instituto L&A  te ofrecemos el mejor servicio: </p>
        <ul class="px-16">
          <p class="text-left text-lg-body-1"><strong> Para crear un departamento desde cero:</strong></p>
          <li
            v-for="ofrece in cero"
            :key="ofrece.texto"
            class="ml-10">
            <p
              class=" text-left text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1">
              {{ ofrece.texto }}
            </p>
          </li>
        </ul>

        <ul class="px-16">
          <p class="text-left text-lg-body-1"><strong> •	En el caso de que tengas organizado tu departamento de inglés y quieras perfeccionar el manejo del mismo o quizás elevar el nivel académico del cuerpo docente y del alumnado</strong></p>
          <li
            v-for="ofrece in armado"
            :key="ofrece.texto"
            class="ml-10">
            <p
              class=" text-left text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1">
              {{ ofrece.texto }}
            </p>
          </li>
        </ul>
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify px-16">
          La calidad de nuestros servicios están garantizados por la experiencia de
          nuestros docentes, un grupo de profesionales en la enseñanza del idioma
          que son reconocidos y respetados por su calidad académica.
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      cero: [
        {texto:"Te ayudamos en la elección del material didáctico audiovisual y textos a utilizar por los docentes."},
        {texto:"Realizamos evaluaciones parciales y finales del alumnado."},
        {texto: "Informes periódicos del alumnado."},
        {texto: "Provisión de programas y exámenes"}
      ],
      armado: [
        {texto:"Seminarios para docentes"},
        {texto:"Proponemos material didáctico audiovisual y textos"},
      ]
    })
  }
</script>

<style>

</style>