<template>
  <v-container>
    <v-card
      class="py-10"
      flat>
      <v-card-title class="text-left mt-10 px-16"> Nuestra Institución</v-card-title>
      <v-card-text class="text-center  px-16">
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify">
          Instituto L&A es una institución académica dedicada a la enseñanza de idiomas y
          artes. Dentro de los idiomas se encuentra inglés, portugués e italiano. En la rama
          del arte podrás encontrar Teatro, Pintura, Dibujo, entre otros.
        </p>
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify">
          Nos encontramos ubicados en pleno centro de la ciudad de Resistencia, a pazos de la plaza central.
        </p>
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify">
          En Instituto L&A se dictan cursos personalizados que permiten un aprendizaje real
          en un ambiente motivador, descontracturado. A partir de metas y objetivos claros,
          ayudamos a nuestros alumnos a llevar a cabo un aprendizaje eficaz, marcado por un plan de estudios a medida y con seguimiento constante
        </p>
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify">
          El aprendizaje se realiza con grupos reducidos, con cargas horarias para cada necesidad.
        </p>
      </v-card-text>
      <v-card-title class="text-left px-16 primary--text"> Misión</v-card-title>
      <v-card-text>
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify px-11">
          Nuestra misión es aportar a la comunidad un lugar
          en donde el arte y las lenguas extranjeras se fusionan
          en un ambiente descontracturado y fructífero para el aprendizaje,
          en donde el alumno sea el centro de todo proceso, ofrecerle metodologías
          en constante actualización y un grupo académico de profesional del mejor nivel.
        </p>

      </v-card-text>
      <v-card-title class="text-left px-16 primary--text"> Visión</v-card-title>
      <v-card-text>
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify px-11">
          Ser un instituto reconocido por contribuir al desarrollo del potencial humano de nuestra comunidad,
          implementando técnicas novedosas de aprendizajes   que vuelvan placenteras
          sus horas de estudio.
        </p>

      </v-card-text>
      <v-card-title class="text-left px-16 primary--text"> Valores</v-card-title>
      <v-card-text>
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify px-11">
          Innovación, colaboración, impacto social y creatividad.
        </p>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Institution'

  }
</script>

<style>

</style>