<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <v-card-text
        class="pt-10"
        flat
        min-height="200">
        <v-row>
          <v-col
            cols="0"
            lg="0"
            sm="0"
            xl="2">
          </v-col>
          <v-col
            cols="12"
            lg="5"
            md="6"
            sm="12"
            xl="4">
            <v-text-field
              v-model="user.name"
              :counter="100"
              label="Nombre completo"
              required
              :rules="nameRules"></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="3"
            sm="12"
            xl="2">
            <v-text-field
              v-model="user.dni"
              :counter="9"
              label="DNI"
              required
              :rules="dniRules"></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="3"
            sm="12"
            xl="2">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              min-width="auto"
              offset-y
              transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Fecha de Nac."
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  :rules="[v => !!v || 'Este item es requerido']"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="date"
                locale="es"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="save"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="0"
            lg="0"
            sm="0"
            xl="2">
          </v-col>
          <v-col
            cols="12"
            lg="5"
            md="6"
            sm="12"
            xl="4">
            <v-text-field
              v-model="user.email"
              :counter="100"
              label="E-mail"
              required
              :rules="emailRules"></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="3"
            sm="12"
            xl="2">
            <v-select
              v-model="user.role"
              item-text="name"
              item-value="name"
              :items="roles"
              label="Rol"
              required
              :rules="[v => !!v || 'Este item es requerido']"></v-select>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="3"
            sm="12"
            xl="2">
            <v-text-field
              v-model="user.telefono"
              label="Telefono"
              required
              :rules="telefonoRules"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="0"
            lg="0"
            sm="0"
            xl="2">
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6"
            sm="12"
            xl="2">
            <v-select
              v-model="user.provincia"
              item-text="name"
              item-value="name"
              :items="provincias"
              label="Provincia"
              :rules="[v => !!v || 'Este item es requerido']"></v-select>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6"
            sm="12"
            xl="2">
            <v-select
              v-model="user.localidad"
              item-text="name"
              item-value="name"
              :items="localidades"
              label="Localidad"
              :rules="[v => !!v || 'Este item es requerido']"></v-select>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6"
            sm="12"
            xl="2">
            <v-text-field
              v-model="user.barrio"
              :counter="100"
              label="Barrio"
              required
              :rules="barrioRules"></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6"
            sm="12"
            xl="2">
            <v-text-field
              v-model="user.domicilio"
              :counter="100"
              label="Domicilio"
              required
              :rules="domicilioRules"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="px-5 py-5 mr-4"
          color="error"
          @click="reset">
          Limpiar
        </v-btn>
        <v-btn
          class="px-5 py-5 mr-4"
          color="success"
          :disabled="!valid"
          :loading="loadingStatus"
          @click="validate">
          Enviar
        </v-btn>
      </v-card-actions>

    </v-form>

  </v-container>

</template>

<script>
  export default {
    data () {
      return {
        user: {
          name:'',
          email:'',
          dni:'',
          fecha_nac:'',
          localidad:'',
          provincia:'',
          barrio:'',
          domicilio:'',
          telefono:'',
          role:'',
        },
        date: null,
        menu: false,
        localidades: [
          {name: 'Resistencia'},
          {name: 'Corrientes Capital'},
          {name: 'Barranqueras'},
          {name: 'Tirol'},
          {name: 'Fontana'},
        ],
        provincias: [
          { name: 'Chaco'},
          { name: 'Corrientes'},
        ],
        valid: true,
        nameRules: [
          v => !!v || 'El nombre es requerido',
          v => (v && v.length <= 100) || 'El nombre debe tener menos de 100 caracteres',
          v => /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g.test(v) || 'No se aceptan números'
        ],
        domicilioRules: [
          v => !!v || 'Domicilio es requerido',
          v => (v && v.length <= 100) || 'El domicilio no puede contener mas de 100 caracteres',
        ],
        barrioRules: [
          v => !!v || 'Barrio es requerido',
          v => (v && v.length <= 100) || 'El barrio no puede contener mas de 100 caracteres',
        ],
        emailRules: [
          v => !!v || 'E-mail es requerido',
          v => /.+@.+\..+/.test(v) || 'Debe ser un email válido',
        ],
        dniRules:[
          v => !!v || 'El dni es requerido',
          v => (v && v.length <= 8) || 'El dni debe tener menos de 9 caracteres',
          v => /^([0-9])*$/g.test(v) || 'Solo puede contener números'
        ],
        telefonoRules:[
          v => !!v || 'El telefono es requerido',
          v => /^([0-9])*$/g.test(v) || 'Solo puede contener números',
        ],
        checkbox: false,
      }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    created() {
      this.$store.dispatch('fetchRoles')
    },
    methods: {
      //guardar fecha
      save (date) {
        this.$refs.menu.save(date)
        this.user.fecha_nac = this.date
      },

      //validar formulario
      validate () {
        if(this.$refs.form.validate()) {
          this.$store.dispatch('register', this.user)
            .then( this.$refs.form.reset() )
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      roles() {
        return this.$store.getters.getRoles
      },
    }
  }
</script>

<style scoped>
/* .v-input {
  min-width:400px !important;
} */
/* @media (max-width: 500px) {
  .v-input {
  min-width:350px !important;
  }
} */
</style>