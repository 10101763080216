<template>
  <div class="header">
    <div class="logo">
      <router-link
        id="logo"
        tag="div"
        to="/">
        <img src="@/assets/logo.svg">
      </router-link>
    </div>
    <div
      id="nav"
      class="">
      <router-link to="/">
        Inicio
      </router-link> |
      <router-link to="/noticias">
        Noticias
      </router-link> |
      <router-link to="/courses">
        Cursos
      </router-link>
      <router-link
        v-if="!user"
        to="/login">
        <button class="btn-login">
          Ingresar
        </button>
      </router-link>
      <Menu
        v-if="user"
        :user="this.user" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Menu from '@/components/Menu.vue'
  export default {
    components:{
      Menu,
    },
    computed: {
      user() {
        return this.$store.getters.getUser;
      }

    },

  // methods: {
  //   handleCommand(command) {
  //     switch (command) {
  //       case "logout": this.$store.dispatch("logout"); break;
  //       case "account":
  //     }
  //   }
  // }
  };
</script>

<style lang="scss">
  @media (max-width: 870px) {
    .header {
    height: 200px !important;
         }
  }
  .btn-login {
    border: 1.5px #ff7f52 solid;
    padding: 8px;
    border-radius: 13px;
    background-color: $tercero;
    border-color: $tercero;
    color:white;
    &:hover {
      background-color: #F8CF90;
      border-color: $tercero;
      color:white;
    }
  }
  .name-user {
    font-weight: bold;
  }
  .logger {
    text-align: right;
    padding-right: 2rem;
    margin-top:10px;
    // p-top: 5px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
    padding: 1rem 5%;
    // background: rgb(251,251,250);
    // background: linear-gradient(90deg, rgba(251,251,250,0.3225665266106) 0%, rgba(246,244,175,0.35) 42%, rgba(242,209,165,1) 75%);
    // box-shadow: 0 5px 10px rgba($color: #000000, $alpha: .2);
    position: relative;
    z-index: 100;
    // background-color: white;
    background-color: #F8CF90;
    height: 90px;

    #logo {
      cursor: pointer;
      max-height: 70px;

      img {
        max-height: 70px;
        width: auto;

        @media (max-width: 870px) {
          max-height: 50px;
        }
      }
    }

    #nav a {
      font-weight: bold;
      color: $gris-medio;
      margin-left: 30px;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 30px;
      }

      &:hover {
        color: $gris-oscuro;
      }
    }

    #nav a.router-link-exact-active {
      &:not(:last-child) {
      color: $tercero;
      padding-bottom: 5px;
      border-bottom: 2px solid $tercero;
      }

    }

  @media (max-width: 678px) {
  #nav a {
      margin-left: 15px;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }

      z-index: 100;
      position: relative;
      flex-direction: column;

      #logo, #nav {
        margin-bottom: 25px;
      }

      #logo img {
        max-height: 100px;
      }
    }
  }
</style>
