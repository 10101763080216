<template>
  <v-main>
    <v-container
      class="vision"
      fluid>
      <div class="content-carrousel">
        <v-row>
          <v-col
            class="text-left"
            cols="12">
            <div class="wave-top" />
          </v-col>
        </v-row>
        <v-carousel
          cycle
          :interval="8000"
          light
          :show-arrows="false"
          :show-arrows-on-hover="false">
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i">
            <v-sheet
              class="carousel-opinion"
              height="400px"
              tile>
              <v-row
                align="center"
                class="fill-height"
                justify="center">
                <div class="display-3">
                  <v-row
                    justify="space-between">
                    <v-avatar
                      class="avatar-alumnos"
                      size="300"
                      style="background-color:white">
                      <v-img
                        alt="John"
                        :src="item.img" />
                    </v-avatar>
                    <div>
                      <p class="opinion subtitle-1 mx-16  text-center">
                        {{item.txt}}
                      </p>
                    </div>
                  </v-row>

                  <!-- <br> -->
                  <!-- Slide {{ item.texto }} -->
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
        <div class="wave-bottom" />
      </div>
    </v-container>
  </v-main>
</template>

<script>
  const img1 = require('../assets/imagen11.jpg');
  const img2 = require('../assets/imagen21.jpg');
  const img3 = require('../assets/imagen31.jpg');
  export default {
    data() {
      return {
        items: [
          //{img: img1, txt:'"Instituto L&A adapta la enseñanza del idioma en base a las necesidades del grupo y del conocimiento del alumno, las propuestas siempre son dinámicas, sin duda es un aliado increíble para mi desarrollo personal. "'},
          {img: img1, txt:'“…puedo destacar que realmente es reconfortante aprender allí, junto con los profesores, excelentes profesionales. Se incentiva el aprendizaje de manera colectiva del alumnado.”'},
          //{img: img2, txt:'"En un principio tenía algo de miedo, pero con el equipo profesional y humano con el que me encontré fue fantástico"'},
          {img: img2, txt:'“Una organización donde puedes estudiar inglés en todos los niveles con diferentes horarios de clases y preparación para exámenes internacionales. La directora Mónica Pierozzi y su equipo, la mejor opción.”'},
          //{img: img3, txt:'""¡Me encanta ser alumna de Instituto L&A! la buena onda que tienen es increíble, sus técnicas de aprendizaje modernas y divertidas son la mejor forma de aprender inglés."'},
          {img: img3, txt:'"Muy contenta con la excelencia de la teacher, agradecida por su infinita paciencia y empatía que alienta clase a clase a seguir."'},
        ]
      };
    },
  };
</script>

<style >

.carousel-opinion {
  background-color:transparent !important;
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top: 100px;

}
.v-carousel {
  height: 550px !important;
}
.content-carrousel {
    justify-items: center;
    align-items: center;
}
.v-carousel__controls {
background-color:transparent !important;
margin-top: 100px;
}
.vision {
  min-width: 100% !important;
  min-height: 100vh;
  background: url('~@/assets/wave-bottom.svg') bottom right / cover no-repeat;

}

.container {
  padding: 0px !important;
}
.wave-top {
    height: 200px;
    background: url('~@/assets/wave-top.png') top left / cover no-repeat;
    margin-bottom: -60px;

}

.opinion {
  padding-top: 85px;
  max-width: 500px;
}
  /* @media (min-width: 800px) {
    .v-carousel__controls {
      margin-top: 150px;
    }
  } */
  @media (max-width: 800px) {
  .opinion {
    max-width: 100% !important;
    color:white !important;
  }
  .avatar-alumnos {
    margin-right: auto;
    margin-left: auto;
    margin-top: 15px;
  }
  .wave-top {
    margin-bottom: 0px;
  }
  .v-carousel__controls {
    margin-top: 0px !important;
  }
  .carousel-opinion {
    margin-top: 0px;
  }

}
</style>
