<template>
  <div class="home">
    <Banner />
    <Servicios />
    <Recomiendan />
    <Contacto />
    <Mapa />
  </div>
</template>

<script>
  import Banner from "@/components/Banner";
  import Recomiendan from "@/components/Recomiendan";
  import Contacto from "@/components/Contacto";
  import Servicios from "@/components/Servicios";
  import Mapa from "@/components/Mapa";

  export default {
    name: "Home",
    components: {
      Banner,
      Recomiendan,
      Contacto,
      Servicios,
      Mapa
    },
  };
</script>
