<template>
  <div>
    <v-app>
      <v-app-bar
        app
        color="orange lighten-3"
        dark>
        <v-app-bar-nav-icon @click.stop="sidebarMenu = !sidebarMenu"></v-app-bar-nav-icon>
        <v-toolbar-title>Panel de Alumno</v-toolbar-title>
        <v-spacer></v-spacer>
        <Menu :user="this.user" />
      </v-app-bar>
      <v-navigation-drawer
        v-model="sidebarMenu"
        app
        color="orange lighten-4"
        dark
        floating
        :mini-variant.sync="mini"
        :permanent="sidebarMenu">
        <v-list dense>
          <v-list-item>
            <v-list-item-action>
              <v-icon @click.stop="sidebarMenu = !sidebarMenu">mdi-chevron-left</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list-item
          class="px-2"
          @click="toggleMini = !toggleMini">
          <v-list-item-avatar>
            <v-icon class="grisoscuro--text">mdi-account-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="text-truncate grisoscuro--text">
            {{ user.usuario.name }}
          </v-list-item-content>
          <v-btn
            icon
            small>
            <v-icon class="grisoscuro--text">mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to=" {name: item.href  }">
            <v-list-item-icon>
              <v-icon color="grisoscuro">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="grisoscuro--text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container
          class="px-4 py-0 fill-height"
          fluid>
          <v-row class="fill-height">
            <v-col>
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-footer
          app
          class="py-3"
          color="grey darken-4">
          <span class="ml-auto overline">Iatek &copy;2020</span>
        </v-footer> -->
      </v-main>
    </v-app>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Menu from '@/components/Menu.vue'
  export default {
    components:{
      Menu,
    },
    data () {
      return {
        url: '',
        selectedItem: 1,
        sidebarMenu: true,
        toggleMini: false,
        items: [
          {
            icon: "mdi-account-multiple",
            title: "Mi Perfil",
            href: "AlumnoPerfil"
          },
          {
            icon: "mdi-account-multiple",
            title: "Mi Cursadas",
            href: "AlumnoCursadas"
          },
        ],
        menu_user: [
          {
            icon: "mdi-logout",
            title: "Salir",
            link: "Logout",
          },
          {
            icon: "mdi-cog-outline",
            title: "Panel",
            link: "AlumnoWelcome",
          },
        ]
      }
    },
    methods: {

    },
    created() {
      this.url = process.env.VUE_APP_API_URL
    },
    computed: {
      ...mapGetters([
        'getUser',
      ]),

      mini: {
        get() {
          return (this.$vuetify.breakpoint.smAndDown || this.toggleMini)
        },
        set() {

        }
      },
      user() {
        return this.$store.getters.getUser
      }
    },
  }
</script>

<style>
.theme--dark.v-application {
    background-color: #181818;
}

.theme--dark.v-card,
.theme--dark.v-data-table,
.theme--dark.v-tabs-items,
.theme--dark.v-tabs .v-tabs-bar {
    background-color: #232323;
}

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}
.v-list-item:hover {
  text-decoration: none;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}

::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(228, 136, 15);
}

::-webkit-scrollbar-thumb {
    background-color: rgb(228, 136, 15);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(228, 136, 15);
    text-decoration: none;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0,0,0,0.6);
    background-color: gray;
}

::-webkit-scrollbar-track:hover {
    background-color: gray;
}
.no-decoration:hover {
  background-color: gray;;
}
/* .list__tile {
    height: auto;
    min-height: 48px;
}

.list__tile__title {
    white-space: normal !important;
    text-overflow: wrap !important;
    word-wrap: break-word;
} */
</style>