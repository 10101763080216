<template>
  <v-container>
    <institution v-if="this.id === 'institucion'" />
  </v-container>
</template>

<script>
  import Institution from "@/components/Footer/Institution.vue";
  export default {
    name: 'FooterElement',
    components: {
      'institution': Institution,
    },
    data: () => ({
      id: '',
    }),
    created() {
      this.id = this.$route.params.id
    }
  }
</script>

<style>

</style>