<template>
  <v-col
    cols="12"
    lg="12"
    md="12"
    sm="12"
    xl="12"
    xs="12">
    <h3 class="text-center mx-10 my-10">Nuestras Noticias</h3>
    <div
      v-if="loadingStatus"
      class="div-loader">
      <scale-loader
        :color="color"
        :size="size"></scale-loader>
    </div>
    <v-row
      v-if="!loadingStatus"
      justify="center">
      <v-col
        v-for="c in shownCards"
        :key="c.title"
        cols="12"
        lg="5"
        md="12"
        sm="12"
        xl="5"
        xs="12">
        <v-card min-height="480">
          <v-card-title>{{c.title}}</v-card-title>
          <div class="contenedor-img">
            <img
              class="img-articles"
              :src="url + '/' +c.id + '/image'">
            <!-- <v-card-subtitle class="text-justify">{{c.body.slice(0,400) + '...'}}
              <a :href="'noticia/'+ c.id">Ver mas</a>
            </v-card-subtitle> -->
            <v-card-subtitle v-html="c.body.slice(0,400)+'...'"></v-card-subtitle>
            <a
              class="mr-10"
              :href="'noticia/'+ c.id"
              style="float:right;">Ver mas</a>
          </div>
          <div class="div-user text-right mr-5 pb-2">
            <!-- <p style="margin-bottom:2px; color:#F2AA9A;">{{ c.user.name }}</p> -->
            <p>{{format(c.created_at)}}</p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
            v-model="page"
            class="my-4"
            :length="this.total"
            :total-visible="7"></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </v-col>

</template>

<script>
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

  import moment from 'moment'
  export default {
    data() {
      return {
        color: '#cc181e',
        color1: '#5bc0de',
        size: '70px',
        margin: '2px',
        radius: '2px',

        page: 1,
        pageSize: 6,
        total: 0,
        url: 'https://instituto-api.herokuapp.com/api/articles',
      }
    },
    components: {
      ScaleLoader,
    },
    methods: {
      format(fecha) {
        return moment(fecha).utc().format('DD/MM/YYYY')
      },

    },
    computed: {
      notice() {
        return this.$store.getters.getNoticias
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      shownCards () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.total = Math.round(this.notice.length / this.pageSize + 1 );
        return this.notice.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
      }
    },
    created() {
      this.$store.dispatch('fetchNoticias')
    }
  }
</script>

<style>
.noticias {
  min-height: calc(100vh - 290px);
  background-color: blanchedalmond;
}

@media (max-width: 400px) {
    .contenedor-img {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .img-articles {
        align-self: center;
        width: 400px;
        height: 280px;
        float: none;
    }
}
.img-articles {
  width: 220px;
  height: 150px;
  margin: 20px;
  float: left;
}
.div-user {
  margin-top: 100px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.div-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 518px)
}
</style>