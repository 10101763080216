<template>
  <v-container>
    <v-main>
      Listado de cursos
    </v-main>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style>

</style>