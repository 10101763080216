<template>
  <v-data-table
    class="elevation-1 py-16 px-10"
    :expanded.sync="expanded"
    :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
    :headers="headers"
    item-key="id"
    :items="this.alumnoCursadas"
    :loading="loadingStatus"
    loading-text="Cargando...por favor aguarde"
    :search="search"
    show-expand
    :single-expand="singleExpand">

    <template
      v-slot:top>
      <v-toolbar
        class="mb-16"
        flat>
        <v-toolbar-title>Lista de mis Cursadas</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          hide-details
          label="Buscar..."
          single-line></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.state="{ item }">
      <h6
        v-if="item.estado === '1'"
        class="green darken-1 grey--text text--lighten-5 text-center py-1 rounded"> Activo</h6>
      <h6
        v-if="item.estado === '0'"
        class="red darken-1 grey--text text--lighten-5 text-center py-1 rounded"> Cerrado</h6>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="d-flex justify-space-around my-4">
          <v-data-table
            :headers="headersNotes"
            :items="item.notes">
          </v-data-table>
        </div>

      </td>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    data: () => ({
      expanded: [],
      singleExpand: true,
      search: '',
      headersNotes: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Cmpron Lectora', value: 'lectora' },
        { text: 'Cmpron Auditiva', value: 'auditiva' },
        { text: 'Prod. Escrita', value: 'escrita' },
        { text: 'Prod. Oral', value: 'oral' },
        { text: 'UOE', value: 'usenglish' },
      ],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Nombre', value: 'name' },
        { text: 'Año', value: 'ano' },
        { text: 'Estado', value: 'state', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
    }),

    computed: {
      alumnoCursadas() {
        return this.$store.getters.getAlumnoCursadas
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Pago' : 'Editar Pago'
      },
    },
    created () {
      this.$store.dispatch('fetchAlumnoCursadas')
    },

    methods: {
      state(item) {
        if(item === '0') return 'Sin pagar'
        else if(item === '1') return 'Pagado'
        else if(item === '2') return 'Anulado'
      },
    },
  }
</script>

<style>

</style>