import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: "#ff7b82",
        primary: "#E08769",
        secondary: "#424242",
        thirdy: "#d1a9ab",
        white: "#000000",
        durazno: "#AB5A3F",
        celeste: "#24ACF2",
        gray: "#cccccc",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        grisoscuro: "#707070",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
