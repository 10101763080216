var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mx-15"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"min-width":"290px","nudge-right":40,"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-10",attrs:{"label":"Año Lectivo","prepend-icon":"event","readonly":""},model:{value:(_vm.data.ano),callback:function ($$v) {_vm.$set(_vm.data, "ano", $$v)},expression:"data.ano"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":this.max,"min":this.min,"no-title":"","reactive":""},on:{"input":_vm.save},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-dialog',{ref:"dialogInicio",attrs:{"persistent":"","return-value":_vm.data.inicio,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.data, "inicio", $event)},"update:return-value":function($event){return _vm.$set(_vm.data, "inicio", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-lg-10 mt-md-10 mt-sm-10",attrs:{"label":"Inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.data.inicio),callback:function ($$v) {_vm.$set(_vm.data, "inicio", $$v)},expression:"data.inicio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalInicio),callback:function ($$v) {_vm.modalInicio=$$v},expression:"modalInicio"}},[_c('v-date-picker',{attrs:{"locale":"es","scrollable":""},model:{value:(_vm.data.inicio),callback:function ($$v) {_vm.$set(_vm.data, "inicio", $$v)},expression:"data.inicio"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.modalInicio = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$refs.dialogInicio.save(_vm.data.inicio)}}},[_vm._v(" Guardar ")])],1)],1),_c('v-dialog',{ref:"dialogFinal",attrs:{"persistent":"","return-value":_vm.data.final,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.data, "final", $event)},"update:return-value":function($event){return _vm.$set(_vm.data, "final", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-lg-10 mt-md-10 mt-sm-10",attrs:{"label":"Final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.data.final),callback:function ($$v) {_vm.$set(_vm.data, "final", $$v)},expression:"data.final"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFinal),callback:function ($$v) {_vm.modalFinal=$$v},expression:"modalFinal"}},[_c('v-date-picker',{attrs:{"locale":"es","scrollable":""},model:{value:(_vm.data.final),callback:function ($$v) {_vm.$set(_vm.data, "final", $$v)},expression:"data.final"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.modalFinal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$refs.dialogFinal.save(_vm.data.final)}}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-card',{staticClass:"d-flex flex-row-reverse my-4 mr-15",attrs:{"flat":"","tile":""}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.crearLectivo}},[_vm._v("Enviar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }