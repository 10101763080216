<template>
  <v-data-table
    class="elevation-1"
    :expanded.sync="expanded"
    :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
    :headers="headers"
    item-key="id"
    :items="cursadas"
    :loading="loadingStatus"
    loading-text="Cargando...por favor aguarde"
    :search="search"
    show-expand
    :single-expand="singleExpand"
    sort-by="calories">
    <template v-slot:top>
      <v-toolbar
        flat
        height="150px">
        <v-row class="flex flex-space-between">
          <v-col
            cols="12"
            lg="6">
            <v-toolbar-title>Lista de Cursadas</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical></v-divider>
            <v-spacer></v-spacer>
          </v-col>

          <v-col
            cols="12"
            lg="6">
            <v-select
              v-model="search"
              autocomplete
              bottom
              item-text="ano"
              item-value="ano"
              :items="lectivos"
              label="Elige año Lectivo"></v-select>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.state="{ item }">
      <h6
        v-if="item.estado === '1'"
        class="green darken-1 grey--text text--lighten-5 text-center py-1 rounded"> Activo</h6>
      <h6
        v-if="item.estado === '0'"
        class="red darken-1 grey--text text--lighten-5 text-center py-1 rounded"> Cerrado</h6>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class="mr-5"
        small
        @click="closeCursada(item)">
        mdi-close-thick
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)">
        mdi-delete
      </v-icon>

    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="d-flex justify-space-around my-4">
          <v-card
            flat
            max-width="300">
            <v-card-title>Curso</v-card-title>
            <v-card-text class="text-justify">
              <p><strong>Nombre:</strong>  {{ item.courses.name}}</p>
              <p><strong>Descripción:</strong> {{ item.courses.description }}</p>
            </v-card-text>
          </v-card>

          <v-card
            flat
            max-width="300">
            <v-card-title>Año Lectivo</v-card-title>
            <v-card-text class="text-justify">
              <p><strong>Año:</strong>  {{ item.lectivo.ano}}</p>
              <p><strong>Inicio:</strong> {{ item.lectivo.inicio }}</p>
              <p><strong>Final:</strong> {{ item.lectivo.fin }}</p>
            </v-card-text>
          </v-card>

          <v-card
            flat
            max-width="300">
            <v-card-title>Profesor</v-card-title>
            <v-card-text class="text-justify">
              <p><strong>ID:</strong>  {{ item.teacher.id}}</p>
              <p><strong>Nombre:</strong>  {{ item.teacher.name}}</p>
              <p><strong>Email:</strong>  {{ item.teacher.email}}</p>
            </v-card-text>
          </v-card>
        </div>
      </td>
    </template>

  </v-data-table>
</template>

<script>
  export default {
    data: () => ({
      lectivo: new Date().toISOString().substr(0, 4),
      expanded: [],
      singleExpand: true,
      search: '',
      menu: false,
      dialogDelete: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Curso', value: 'courses.name' },
        { text: 'Año Lectivo', value: 'lectivo.ano' },
        { text: 'Profesor', value: 'teacher.name' },
        { text: 'División', value: 'division' },
        { text: 'Estado', value: 'state', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      editedIndex: -1,
      editedItem: {
        id: '',
        estado:'',
      },

      defaultItem: {
        id: '',
        estado:'',
      },
      user: {},
    }),

    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      lectivos() {
        return this.$store.getters.getLectivos
      },
      cursadas() {
        return this.$store.getters.getCursadas
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },

    created () {
      this.$store.dispatch('fetchCursadas')
      this.$store.dispatch('fetchLectivo')
    },

    methods: {
      async deleteItem(item) {
        let res = await this.$confirm('¿Quiere eliminar esta cursada?', { title: 'Cursada' })
        if (res) {
          this.editedItem = Object.assign({}, item)
          this.$store.dispatch('deleteCursada', this.editedItem)
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async closeCursada(item) {
        let res = await this.$confirm('¿Quiere cerrar esta cursada?', { title: 'Cursada' })
        if (res) {
          this.editedItem = Object.assign({}, item)
          this.editedItem.estado = '0'
          this.$store.dispatch('updateCursada', this.editedItem)
        }
      },

    },
  }
</script>

<style>

</style>