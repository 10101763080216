<template>
  <v-row
    align-content="center"
    class="flex flex-wrap  text--center  grey lighten-2"
    justify="center">
    <v-card
      class="rounded-lg"
      max-width="400"
      min-height="300">
      <v-card-title class="justify-center">Verificación de Email</v-card-title>
      <v-card-text class="text-justify">
        <p>Gracias por verificar su dirección de correo, asi podremos estar seguros de este canal de
          comunicación con usted.
        </p>
        <p>Muchas Gracias por confiar en nosotros.</p>
      </v-card-text>
      <v-card-actions class="text--center">
        <v-flex text-center>
          <v-btn
            class="primary"
            @click.prevent="sendVerification()">
            Verificar Email
          </v-btn>
        </v-flex>
      </v-card-actions>
      <v-flex text-center>
        <div class="mt-5 primary--text">
          <a @click="probando()">Reenviar correo de verificación</a>
        </div>

      </v-flex>

    </v-card>

  </v-row>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  export default {
    methods: {
      async sendVerification() {
        const { queryURL } = this.$route.query;
        this.send(queryURL)
      },
      probando() {
        const { queryURL } = this.$route.query;
        let ruta = queryURL.replace('verify', 'resend')
        this.send(ruta)
      },
      async send(variable) {
        await axios.get(variable, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
          }
        }).then(x => {
          Vue.toasted.global.success(x.data.data.message);
        }).catch(x => {
          Vue.toasted.global.error(x.response.data.data.errors);
        });
      }
    }
  }
</script>
<style scoped>

</style>