<template>
  <v-data-table
    class="elevation-1"
    :expanded.sync="expanded"
    :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
    :headers="headers"
    item-key="name"
    :items="categorias"
    :loading="loadingStatus"
    loading-text="Cargando...por favor aguarde"
    :search="search"
    show-expand
    :single-expand="singleExpand">
    <template v-slot:top>
      <v-toolbar
        flat
        height="150px">
        <v-row class="flex flex-space-between">
          <v-col
            cols="12"
            lg="6">
            <v-toolbar-title>Lista de Categorias</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical></v-divider>
            <v-spacer></v-spacer>
          </v-col>
          <!--
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          hide-details
          label="Buscar..."
          single-line></v-text-field> -->
          <v-col
            cols="12"
            lg="6">
            <v-dialog
              v-model="dialog"
              max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mb-2"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on">
                  Crear Categoria
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text class="mt-7">
                  <v-container>
                    <v-form
                      ref="entryForm"
                      v-model="valid"
                      lazy-validation>
                      <div class="d-flex align-content-center justify-center flex-wrap">
                        <div
                          v-if="editedIndex === -1"
                          class="mr-5 "
                          style="width:100% align-content:center">
                          <v-img
                            class="primary"
                            height="200"
                            max-width="250px"
                            :src="url" />
                          <v-file-input
                            v-model="editedItem.image"
                            accept="image/*"
                            label="Sube su imagen"
                            name="image"
                            :rules="imageRules"
                            style="width:200px;"
                            type="file" />
                        </div>
                        <div class="mr-10 mb-15 text-center">
                          <v-text-field
                            v-model="editedItem.name"
                            hide-details="auto"
                            label="Título"
                            required
                            :rules="nameRules"></v-text-field>
                          <v-textarea
                            v-model="editedItem.description"
                            class="mt-3"
                            hide-details="auto"
                            label="Descripción"
                            outlined
                            required
                            :rules="descriptionRules"></v-textarea>

                        </div>

                      </div>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    v-if="editedIndex !== -1"
                    color="blue darken-1"
                    text
                    @click="save">
                    Guardar
                  </v-btn>
                  <v-btn
                    v-else
                    class="mr-4"
                    color="success"
                    :disabled="!valid"
                    :loading="loadingStatus"
                    @click="validate()">
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class="mr-2"
        small
        @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)">
        mdi-delete
      </v-icon>

    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="d-flex justify-space-around my-4">
          <div>

            <div class="contenedor-img">
              <img
                class="img-articles"
                :src="page + '/' +item.id + '/image'">
            </div>
          </div>
          <div class="mt-10">
            <p><strong>Nombre:</strong> {{ item.name }}</p>
            <p><strong>Descripción:</strong> {{ item.description }}</p>
          </div>
        </div>

      </td>
    </template>
    <!-- <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="categories">
        Reset
      </v-btn>
    </template> -->
  </v-data-table>
</template>

<script>
  export default {
    data: () => ({
      page: 'https://instituto-api.herokuapp.com/api/category',
      valid: true,
      expanded: [],
      singleExpand: false,
      search: '',
      date: null,
      menu: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Nombre', value: 'name' },
        { text: 'Acciones', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],

      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
        image:null,
        description:''
      },
      defaultItem: {
        id: '',
        name: '',
        image:null,
        description:''
      },
      user: {},
      nameRules: [
        value => !!value || 'Campo requerido',
        //value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 40) || 'Máximo 40 caracter',
      ],
      imageRules: [
        value => !!value || 'Campo requerido',
      ],
      descriptionRules: [
        value => !!value || 'Campo requerido',
        //value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 200) || 'Máximo 200 caracter',
      ],
    }),

    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      categorias() {
        return this.$store.getters.getCategories
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Categoria' : 'Editar Categoria'
      },
      url() {
        if (!this.editedItem.image) return;
        return URL.createObjectURL(this.editedItem.image);
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.$store.dispatch('fetchCategories')
    },

    methods: {
      editItem (item) {
        this.editedIndex = this.categorias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      async deleteItem (item) {
        let res = await this.$confirm('¿Quiere eliminar esta categoría?', { title: 'Categoría' })
        if (res) {
          this.editedItem = Object.assign({}, item)
          this.$store.dispatch('deleteCategory', this.editedItem)
        }
      },



      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          if(this.$refs.entryForm.validate()) {
            this.$store.dispatch('updateCategory', this.editedItem)
            this.close()
          }
        }


      },
      //validar formulario
      validate () {
        if(this.$refs.entryForm.validate()) {
          //Creamos el formData
          var data = new  FormData();
          //Añadimos la imagen seleccionada
          data.append('name', this.editedItem.name);
          data.append('description', this.editedItem.description);
          data.append('image', this.editedItem.image);
          //Añadimos el método PUT dentro del formData
          // Como lo hacíamos desde un formulario simple _(no ajax)_
          this.$store.dispatch('createCategory', data)
          this.$refs.entryForm.reset()
          this.close()
        }
      },
    },
  }
</script>

<style>

</style>