<template>
  <v-container>
    <v-form
      ref="cursadaForm"
      v-model="valid"
      lazy-validation>
      <v-card-text
        class="pt-10"
        min-height="200">
        <v-row>
          <v-col
            cols="12"
            lg="0"
            md="0"
            sm="0"
            xl="3">
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            xl="4">
            <v-select
              v-model="inscription.cursada_id"
              autocomplete
              bottom
              item-text="courses.name"
              item-value="id"
              :items="cursadasCurrent"
              label="Elige la cursada"
              :rules="[v => !!v || 'La cursada es requerida']"></v-select>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            xl="2">
            <v-text-field
              v-model="inscription.price_matricula"
              label="Precio de la Matrícula"
              prepend-icon="mdi-cash"
              required
              :rules="priceRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="0"
            md="0"
            sm="0"
            xl="3">
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            xl="4">
            <v-autocomplete
              v-model="inscription.user_id"
              :filter="customFilter"
              item-text="name"
              item-value="id"
              :items="users"
              label="Elige al usuario"
              :rules="[v => !!v || 'El usuario es requerido']"></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            xl="2">
            <v-text-field
              v-model="inscription.becado"
              label="Becado (1.00 = 0% - 0.95 = 5%)"
              prepend-icon="mdi-percent"
              required
              :rules="becadoRules"></v-text-field>
          </v-col>
        </v-row>




      </v-card-text>

      <v-card-actions class="d-flex flex-row-reverse my-4">
        <v-btn
          class="px-5 py-5"
          color="primary"
          :disabled="!valid"
          :loading="loadingStatus"
          @click="validate">
          Crear
        </v-btn>
      </v-card-actions>

    </v-form>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      valid: true,
      inscription: {
        user_id: '',
        cursada_id: '',
        becado: '1.00',
        price_matricula:'',
      },
      priceRules:[
        v => !!v || 'El precio es requerido',
        v => (v && v.length <= 5) || 'El precio debe tener menos de 6 caracteres',
        v => /^([0-9])*$/g.test(v) || 'Solo puede contener números'
      ],
      becadoRules:[
        v => !!v || 'El precio es requerido',
        v => /^(0+\.[0-9][0-9]|1+\.[0][0])?$/g.test(v) || 'No tiene el formato necesario'
      ],
    }),
    methods: {
      validate () {
        if(this.$refs.cursadaForm.validate()) {
          this.$store.dispatch('createInscription', this.inscription)
        }

      },
      customFilter (item, queryText, itemText) {
        const textOne = item.name.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1
      },
    },
    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      cursadas() {
        return this.$store.getters.getCursadas
      },
      users() {
        return this.$store.getters.getUsers
      },
      cursadasCurrent() {
        let current = this.cursadas.filter(courses => courses.lectivo.ano === new Date().toISOString().substr(0, 4) );
        return current;
      }
    },
    created() {
      this.$store.dispatch('fetchUsers'),
      this.$store.dispatch('fetchCursadas')
    }
  }
</script>

<style>

</style>