<template>
  <v-data-table
    class="elevation-1"
    :expanded.sync="expanded"
    :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
    :headers="headers"
    item-key="name"
    :items="courses"
    :loading="loadingStatus"
    loading-text="Cargando...por favor aguarde"
    :search="search"
    show-expand
    :single-expand="singleExpand">
    <template v-slot:top>
      <v-toolbar
        flat
        height="150px">
        <v-row class="flex flex-space-between">
          <v-col
            cols="12"
            lg="6">
            <v-toolbar-title>Lista de Cursos</v-toolbar-title>
            <v-divider
              inset
              vertical></v-divider>
            <v-spacer></v-spacer>
          </v-col>
          <v-col
            cols="12"
            lg="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="mb-3"
              hide-details
              label="Buscar..."
              single-line></v-text-field>

            <v-dialog
              v-model="dialog"
              max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mb-15"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on">
                  Crear Curso
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text class="mt-7">
                  <v-container>
                    <v-form
                      ref="courseForm"
                      v-model="valid"
                      lazy-validation>
                      <div class="d-flex align-content-center justify-center flex-wrap">
                        <div class="mr-10 mb-15 text-center">
                          <v-text-field
                            v-model="editedItem.name"
                            hide-details="auto"
                            label="Título"
                            required
                            :rules="nameRules"></v-text-field>
                          <v-select
                            v-model="editedItem.category_id"
                            autocomplete
                            bottom

                            item-text="name"
                            item-value="id"
                            :items="categorias"
                            label="Elige año categpría"
                            :rules="[v => !!v || 'La categoría  es requerido']"></v-select>
                          <v-textarea
                            v-model="editedItem.description"
                            class="mt-3"
                            hide-details="auto"
                            label="Descripción"
                            outlined
                            required
                            :rules="descriptionRules"></v-textarea>

                        </div>

                      </div>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    v-if="editedIndex !== -1"
                    color="blue darken-1"
                    text
                    @click="save">
                    Guardar
                  </v-btn>
                  <v-btn
                    v-else
                    class="mr-4"
                    color="success"
                    :disabled="!valid"
                    :loading="loadingStatus"
                    @click="validate()">
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class="mr-2"
        small
        @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)">
        mdi-delete
      </v-icon>

    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="d-flex justify-space-around my-4">
          <div class="mt-10">
            <p><strong>Nombre:</strong> {{ item.name }}</p>
            <p><strong>Descripción:</strong> {{ item.description }}</p>
          </div>
        </div>

      </td>
    </template>
    <!-- <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="categories">
        Reset
      </v-btn>
    </template> -->
  </v-data-table>
</template>

<script>
  export default {
    data: () => ({
      valid: true,
      expanded: [],
      singleExpand: false,
      search: '',
      date: null,
      menu: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Nombre', value: 'name' },
        { text: 'Acciones', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],

      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
        category_id:'',
        description:''
      },
      defaultItem: {
        id: '',
        name: '',
        category_id:'',
        description:''
      },
      user: {},
      nameRules: [
        value => !!value || 'Campo requerido',
        //value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 100) || 'Máximo 100 caracter',
      ],
      descriptionRules: [
        value => !!value || 'Campo requerido',
        //value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 200) || 'Máximo 300 caracter',
      ],
    }),

    computed: {
      categorias() {
        return this.$store.getters.getCategories
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      courses() {
        return this.$store.getters.getCourses
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Curso' : 'Editar Curso'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.$store.dispatch('fetchCourses')
      this.$store.dispatch('fetchCategories')
    },

    methods: {
      editItem (item) {
        this.editedIndex = this.courses.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      // deleteItem (item) {
      //   this.editedIndex = item.id
      //   this.editedItem = Object.assign({}, item)
      //   this.dialogDelete = true
      // },
      async deleteItem (item) {
        let res = await this.$confirm('Si borra este curso se borraran todas las cursadas que dependan de este curso genérico ¿Quiere borrar este curso?', { title: 'Curso Genérico' })
        if (res) {
          this.$store.dispatch('deleteCourse', item)
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          if(this.$refs.courseForm.validate()) {
            this.$store.dispatch('updateCourse', this.editedItem)
            this.close()
          }
        }


      },
      //validar formulario
      validate () {
        if(this.$refs.courseForm.validate()) {
          this.$store.dispatch('createCourse', this.editedItem)
          this.$refs.courseForm.reset()
          this.close()
        }
      },
    },
  }
</script>

<style>

</style>