  <!-- <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div> -->
<template>
  <v-app>
    <Header v-if="showHeader" />
    <transition>
      <router-view />
    </transition>
    <Footer v-if="showHeader" />
  </v-app>
</template>

<script>
  import Header from "@/components/Header";
  import Footer from "@/components/Footer.vue";

  export default {
    components: { Header, Footer },
    computed: {
      showHeader() {
        return !this.$route.meta.noHeader;
      }
    }

  };
</script>

  <style lang="scss">
    @import "@/scss/_base.scss";
  </style>
