<template>
  <v-data-table
    class="elevation-1"
    :expanded.sync="expanded"
    :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
    :headers="headers"
    item-key="id"
    :items="this.noticias"
    :loading="loadingStatus"
    loading-text="Cargando...por favor aguarde"
    :search="search"
    show-expand
    :single-expand="singleExpand">
    <template v-slot:top>
      <v-toolbar
        flat
        height="150px">
        <v-row class="flex flex-space-between">
          <v-col
            cols="12"
            lg="6">
            <v-toolbar-title>Lista de Noticias</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical></v-divider>
            <v-spacer></v-spacer>
          </v-col>
          <v-col
            cols="12"
            lg="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              hide-details
              label="Buscar..."
              single-line></v-text-field>

            <v-dialog
              v-model="dialog"
              max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Editar Noticia</span>
                </v-card-title>
                <v-card-text class="mt-7">
                  <v-container>
                    <v-row grow>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12">
                        <v-text-field
                          v-model="editedItem.title"
                          label="Nombre"
                          :rules="[v => !!v || 'Este item es requerido']"></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12">
                        <v-select
                          v-model="editedItem.category_id"
                          item-text="name"
                          item-value="id"
                          :items="categories"
                          label="Seleccionar una Categoría"
                          :rules="[v => !!v || 'Este item es requerido']"></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12">
                        <ckeditor
                          v-model="editedItem.body"
                          :config="editorConfig"
                          :editor="editor"
                          style="height:200px;"></ckeditor>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close">
                    Cerrar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.fecha="{ item }">
      {{ format(item.created_at)}}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class="mr-2"
        small
        @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="d-flex justify-space-around flex-wrap my-4">
          <div>

            <div class="contenedor-img">
              <img
                class="img-articles"
                :src="page + '/' +item.id + '/image'">
            </div>
          </div>
          <div
            class="mt-10"
            style="min-width:200px">
            <p><strong>ID:</strong>  {{ item.id}}</p>
            <p><strong>Titulo:</strong> {{ item.title }}</p>
            <p><strong>Categoria:</strong> {{ categoryName(item.category_id)}}</p>
            <p><strong>Cuerpo:</strong><a
              :href="'http://institutolya.com.ar/noticia/' + item.id"
              rel="noopener noreferrer"
              target="_blank"> VER AQUI</a></p>
          </div>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
  import moment from 'moment'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  export default {
    data: () => ({
      editor: ClassicEditor,
      page: 'https://instituto-api.herokuapp.com/api/articles',
      cursada: '',
      filterInscriptions: [],
      valid: true,
      expanded: [],
      singleExpand: true,
      search: '',
      date: null,
      menu: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Titulo', value: 'title' },
        { text: 'Fecha', value: 'fecha', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],

      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      nameRules: [
        value => !!value || 'Campo requerido',
        value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 40) || 'Máximo 40 caracter',
      ],
      descriptionRules: [
        value => !!value || 'Campo requerido',
        value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 200) || 'Máximo 200 caracter',
      ],
      editorConfig: {
        // The configuration of the rich-text editor.
        toolbar: [
          ['Bold'],
          ['Italic'],
          ['Link'],
          ['Undo'],
          ['Redo'],
          ['bulletedList'],
          ['numberedList']

        ],
      }
    }),

    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      categories() {
        return this.$store.getters.getGroups
      },
      noticias() {
        return this.$store.getters.getNoticias
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Pago' : 'Editar Pago'
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.$store.dispatch('fetchNoticias')
      this.$store.dispatch('fetchGroups')
    },
    methods: {
      format(date) {
        return moment(date).format('DD/MM/YY');
      },
      customFilter (item, queryText, itemText) {
        const textOne = item.name.toLowerCase()
        const textTwo = item.dni.toString()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
      editItem (item) {
        this.editedIndex = this.noticias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        let res = await this.$confirm('¿Quiere borrar esta Noticia?', { title: 'Noticias' })
        if (res) {
          this.editedIndex = item.id
          this.editedItem = Object.assign({}, item)
          this.$store.dispatch('deleteNotice', this.editedItem)
        }
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          this.$store.dispatch('updateNotice', this.editedItem)
        }
        this.close()
      },
      categoryName(id) {
        let category_name = this.categories.filter(filtrado => filtrado.id === id)
        if(category_name.length !== 0) return category_name[0].name
      }
    },
  }
</script>

<style>


</style>