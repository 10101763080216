<template>
  <v-container>
    <v-main>
      <v-col
        cols="12"
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12">
        <h3 class="text-center mx-10 my-10">Categorias de Cursos</h3>
        <div
          v-if="loadingStatus"
          class="mb-15">
          <scale-loader
            color="#000000"
            size="45"></scale-loader>
        </div>
        <v-row justify="center">
          <v-col
            v-for="c in groups"
            :key="c.id"
            cols="12"
            lg="5"
            md="12"
            sm="12"
            xl="5"
            xs="12">
            <v-hover
              v-slot="{ hover }"
              open-delay="200">
              <v-card
                class="grey lighten-2 mb-5 text-center rounded-lg"
                :class="{ 'on-hover': hover }"
                :elevation="hover ? 16 : 2"
                min-height="480">
                <h2 class="justify-center py-3">{{c.name}}</h2>
                <div
                  class="contenedor-img ">
                  <img
                    height="200"
                    :src="url + '/' +c.id + '/image'"
                    width="300">
                  <v-card-subtitle>{{c.description }}</v-card-subtitle>
                  <v-btn
                    class="mt-10 btn-course"
                    dark
                    link
                    rounded
                    :to=" 'courses/' + c.id">
                    Ver Cursos
                  </v-btn>
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-main>
  </v-container>
</template>

<script>
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  export default {
    components: {
      ScaleLoader,
    },
    data () {
      return {
        url: 'https://instituto-api.herokuapp.com/api/category'
      }
    },

    computed: {
      groups() {
        return this.$store.getters.getGroups
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
    },
    created(){
      this.$store.dispatch('fetchGroups');
    }
  }
</script>

<style>
.btn-course {
    width: 200px;
    background-color:white !important;
    color: rgb(255, 115, 0) !important;
    border: 1px solid rgb(255, 115, 0);
    text-decoration: none !important;
    position: absolute;
    bottom: 40px;
    left: calc(50% - 100px);
}
.btn-course:hover {
    background-color:rgb(255, 115, 0) !important;
    color:white !important;
}
</style>