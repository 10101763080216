<template>
  <div>
    <h1 class="font-weight-light my-10 ml-5">Pagos</h1>
    <v-card>
      <v-row wrap>
        <v-col
          class="py-1 elevation-2"
          grow>
          <v-tabs
            v-model="tab"
            class="fill-width"
            grow
            height="60px"
            show-arrows>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="t in tabs"
              :key="t.name">
              <div class="caption py-1"> {{t.name}}</div>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            class="pa-4"
            grow>
            <v-tab-item>
              <div class="mt-10">
                <ListarxCursos />
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="mt-10">
                <ListarxAlumnos />
              </div>
            </v-tab-item>

          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>


<script>

  import ListarxCursos from "@/components/admin/components/PagosListarCursos.vue";
  import ListarxAlumnos from "@/components/admin/components/PagosListarAlumnos.vue";
  export default {
    components: {
      ListarxCursos,
      ListarxAlumnos,
    },
    data () {
      return {

        tab: 0,
        tabs: [
          {name: 'Listar por Cursada', icon: 'mdi-trash-can'},
          {name: 'Listar por Alumno', icon: 'mdi-email-send'},

        ],

      }
    },

  }
</script>

<style scoped>

</style>