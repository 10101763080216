<template>
  <v-data-table
    class="elevation-1"
    :expanded.sync="expanded"
    :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
    :headers="headers"
    item-key="id"
    :items="usuarios"
    :loading="loadingStatus"
    loading-text="Cargando...por favor aguarde"
    :search="search"
    show-expand
    :single-expand="singleExpand"
    sort-by="calories">
    <template v-slot:top>
      <v-toolbar
        flat
        height="150px">
        <v-row class="flex flex-space-between">
          <v-col
            cols="12"
            lg="6">
            <v-toolbar-title>Lista de Alumnos</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical></v-divider>

          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            lg="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              hide-details
              label="Buscar..."
              single-line
              style="width:300px;"></v-text-field>

            <v-dialog
              v-if="editedItem.profile"
              v-model="dialog"
              max-width="550px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row grow>
                      <v-col
                        class="mt-5"
                        cols="12"
                        md="6"
                        sm="6">
                        <!-- <v-text-field
                      v-model="editedItem.name"
                      label="Nombre"></v-text-field> -->
                        <strong>Nombre:</strong> {{editedItem.name}}
                      </v-col>
                      <v-col
                        class="mt-5"
                        cols="12"
                        md="6"
                        sm="6">
                        <!-- <v-text-field
                      v-model="editedItem.email"
                      label="E-mail"></v-text-field> -->
                        <strong>Email:</strong> {{editedItem.email}}
                      </v-col>
                      <v-col
                        class="mt-5"
                        cols="12"
                        md="6"
                        sm="6">
                        <!-- <v-text-field
                      v-model="editedItem.dni"
                      label="DNI"></v-text-field> -->
                        <strong>DNI:</strong> {{editedItem.profile.dni}}
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6">
                        <v-text-field
                          v-model="editedItem.profile.telefono"
                          label="Telefono"
                          :rules="telefonoRules"></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6">
                        <v-select
                          v-model="editedItem.profile.localidad"
                          item-text="name"
                          item-value="name"
                          :items="localidades"
                          label="Seleccionar un Localidad"
                          :rules="textRules"></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6">
                        <v-select
                          v-model="editedItem.profile.provincia"
                          item-text="name"
                          item-value="name"
                          :items="provincias"
                          label="Seleccionar un Provincia"
                          :rules="textRules"></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          min-width="auto"
                          offset-y
                          transition="scale-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.profile.fecha_nac"
                              label="Fecha de nacimiento"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :rules="[v => !!v || 'Este item es requerido']"
                              v-on="on"></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="editedItem.profile.fecha_nac"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1950-01-01"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6">
                        <v-text-field
                          v-model="editedItem.profile.domicilio"
                          label="Dirección"
                          :rules="textRules"></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6">
                        <v-text-field
                          v-model="editedItem.profile.barrio"
                          label="Barrio"
                          :rules="textRules"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class="mr-2"
        small
        @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)">
        mdi-delete
      </v-icon>

    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row class="d-flex justify-space-around my-4">
          <v-col
            cols="12"
            lg="6">
            <p><strong>Fecha de Nacimiento:</strong>  {{ item.profile.fecha_nac}}</p>
            <p><strong>Telefono:</strong> {{ item.profile.telefono }}</p>
            <p><strong>Email:</strong> {{ item.email }}</p>
          </v-col>
          <v-col
            cols="12"
            lg="6">
            <p><strong>Provincia:</strong> {{ item.profile.provincia }}</p>
            <p><strong>Localidad:</strong> {{ item.profile.localidad }}</p>
            <p><strong>Barrio:</strong> {{ item.profile.barrio }}</p>
            <p><strong>Domicilio:</strong> {{ item.profile.domicilio }}</p>
          </v-col>
        </v-row>

      </td>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    data: () => ({
      expanded: [],
      singleExpand: true,
      search: '',
      date: null,
      menu: false,
      dialog: false,
      dialogDelete: false,
      textRules: [
        v => !!v || 'Este item  es requerido',
        v => (v && v.length <= 100) || 'El item no puede contener mas de 100 caracteres',
      ],
      emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'Debe ser un email válido',
      ],
      dniRules:[
        v => !!v || 'El dni es requerido',
        v => (v && v.length <= 8) || 'El dni debe tener menos de 9 caracteres',
        v => /^([0-9])*$/g.test(v) || 'Solo puede contener números'
      ],
      telefonoRules:[
        v => !!v || 'El telefono es requerido',
        v => /^([0-9])*$/g.test(v) || 'Solo puede contener números',
      ],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Nombre', value: 'name' },
        { text: 'DNI', value: 'profile.dni' },
        { text: 'Acciones', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      localidades: [
        {name: 'Resistencia'},
        {name: 'Corrientes Capital'},
        {name: 'Barranqueras'},
        {name: 'Tirol'},
        {name: 'Fontana'},
      ],
      provincias: [
        { name: 'Chaco'},
        { name: 'Corrientes'},
      ],
      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
        email:'',
        dni: '',
        fecha_nac: '',
        localidad: '',
        provincia: '',
        domicilio: '',
        barrio:'',
        telefono: '',
      },

      defaultItem: {
        id: '',
        name: '',
        email:'',
        dni: '',
        fecha_nac: '',
        localidad: '',
        provincia: '',
        domicilio: '',
        barrio:'',
        telefono: '',
      },
      user: {},
    }),

    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      usuarios() {
        return this.$store.getters.getUsers
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Alumno' : 'Editar Alumno'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },

    created () {
      this.$store.dispatch('fetchUsers')
    },

    methods: {

      editItem (item) {
        this.editedIndex = this.usuarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      async deleteItem (item) {
        let res = await this.$confirm('¿Quiere eliminar este usuario?', { title: 'Usuarios' })
        if (res) {
          this.editedItem = Object.assign({}, item)
          this.$store.dispatch('fetchDeleteUser', this.editedItem)
        }
      },


      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          this.$store.dispatch('updateUser', this.editedItem.profile)
        } else {
          //this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>

<style>
/* intercalado de color en el datatable */
 tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .10);
}

  .v-data-table-header {
    background-color: rgba(182, 183, 187);
  }

  .v-data-footer {
    background-color: rgb(250 ,250, 250);
  }


</style>