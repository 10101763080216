<template>
  <v-container>
    <div class="px-16 py-10">
      <p class="text-h4">Perfil de usuario</p>
      <v-row class="flex flex-wrap justify-space-between">
        <v-col
          class="mt-10"
          cols="8"
          lg="4">
          <p><strong>Nombre: </strong>{{usuario.name}}</p>
          <p><strong>DNI: </strong>{{profile.dni}}</p>
          <p><strong>Fecha de Nacimiento: </strong>{{format(usuario.profile.fecha_nac)}}</p>
          <p><strong>Email: </strong>{{ usuario.email}}</p>
          <v-switch
            v-model="switch1"
            color="red"
            hide-details
            label="Permitir modificar"></v-switch>
        </v-col>
        <v-col
          class="align-center flex flex-space-around"
          cols="8"
          lg="4">
          <v-card
            class="py-1 pt-5 px-10"
            min-width="280">
            <v-card-text>
              <v-img
                class="primary lighten-2"
                height="150"
                max-width="150"
                :src="imagePrv" />
              <v-file-input
                v-model="image"
                accept="image/*"
                name="image"
                style="width:150px"
                type="file"
                @change="url()" />
            </v-card-text>

            <v-card-actions>
              <v-flex
                class="mb-2"
                text-center>
                <v-btn
                  class="primary flex flex-wrap"
                  :disabled="activate"
                  :loading="loadingStatus"
                  width="150"
                  @click="changeImage()">
                  GUARDAR
                </v-btn>
              </v-flex>
            </v-card-actions>

          </v-card>

        </v-col>
      </v-row>
      <!-- cambiar -->
      <v-form
        ref="formPerfil"
        v-model="valid"
        lazy-validation>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="4">

            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4">
              <v-text-field
                v-model="profile.provincia"
                :counter="30"
                label="Provincia"
                :readonly="isDisabled"
                required
                :rules="textRules"></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4">
              <v-text-field
                v-model="profile.localidad"
                :counter="20"
                label="Localidad"
                :readonly="isDisabled"
                required
                :rules="textRules"></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4">
              <v-text-field
                v-model="profile.barrio"
                :counter="20"
                label="Barrio"
                :readonly="isDisabled"
                required
                :rules="textRules"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="8">
              <v-text-field
                v-model="profile.domicilio"
                :counter="50"
                label="Direccion"
                :readonly="isDisabled"
                required
                :rules="textRules"></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4">
              <v-text-field
                v-model="profile.telefono"
                :counter="12"
                label="Telefono"
                :readonly="isDisabled"
                required
                :rules="numberRule"></v-text-field>
            </v-col>
          </v-row>
          <div
            class="mt-15">
            <v-btn
              class="mr-4"
              color="success"
              :disabled="isDisabled"
              :loading="loadingStatus"
              @click="validate">
              Enviar
            </v-btn>
          </div>
        </v-container>
      </v-form>
    </div>
  </v-container>
</template>

<script>
  import moment from 'moment'
  export default {
    data () {
      return {
        activate: true,
        avatar:'',
        image:null,
        switch1: false,
        desactivado: true,
        valid: true,
        profile: '',
        usuario: {
          email:'',
          provincia:'',
          localidad:'',
          barrio:'',
          direccion:'',
          telefono:'',
        },
        nombre: '',
        lastname: '',
        textRules: [
          (v) => !!v || "El campo es requerido",
          v => (v && v.length <= 100) || "Este campo no puede tener mas de 100 dígitos"
        ],
        numberRule: [
          (v) => !!v || "El campo es requerido",
          v => /^([0-9])*$/g.test(v) || 'Solo puede contener números',
          v => (v && v.length <= 15) || 'El campo no puede tener mas de 15 dígitos',
        ],
        email: '',
        emailRules: [
          v => !!v || 'El mail es requerido',
          v => /.+@.+/.test(v) || 'El mail debe ser válido',
        ],
      }
    },
    computed: {
      user() {
        return this.$store.getters.getUser
      },
      isDisabled() {
        return !this.switch1
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
    },
    created() {
      const item = this.$store.getters.getUser
      this.imagePrv = item.avatar
      this.usuario = Object.assign({}, item.usuario)
      this.profile = Object.assign({}, item.profile)
    },
    methods: {
      changeImage() {
        var data = new  FormData();
        data.append('avatar', this.image);
        this.$store.dispatch('updateUserImage', data)
      },
      url() {
        if (!this.image) return;
        this.activate = false
        this.imagePrv =  URL.createObjectURL(this.image);
      },
      validate () {
        if(this.$refs.formPerfil.validate()) {
          this.$store.dispatch('updateUserSelf', this.profile)
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      format(date) {
        return moment(date).format('DD/MM/YYYY')
      },

    }
  }
</script>

<style>

</style>