<template>
  <main>
    <v-container
      class="logout"
      fill-height
      fluid>
      <v-layout
        align-center
        flex
        justify-center>
        <v-flex
          elevation-6
          lg4
          md4
          sm6
          xs12>
          <v-toolbar class="pt-5 orange lighten-4 ingreso-izquierdo">
            <v-toolbar-items>
              <v-toolbar-title class="white--text">
                <h4>Hasta Luego!</h4>
              </v-toolbar-title>
            </v-toolbar-items>
          </v-toolbar>
          <v-card>
            <v-card-text
              class="pt-13 ingreso-derecho">
              <div>
                <h4>{{user().usuario.name}}</h4>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </main>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    methods: {
      user() {
        return this.$store.getters.getUser
      },
      logout() {
        return this.$store.dispatch('logout');
      },
      callFunction: function () {
        var v = this;
        setTimeout(function () {
          v.logout();
        }, 3000);
      }
    },
    mounted() {
      this.callFunction()
    },

  }
</script>

<style>
  .logout {
    width: 100%;
    height: 100vh;
  }
  .ingreso-izquierdo {
     animation: lightSpeedInLeft;
     animation-duration: 3s;
  }
  .ingreso-derecho {
     animation: rotateInDownRight;
     animation-duration: 3s;
  }
</style>