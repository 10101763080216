<template>
  <v-container>
    <sidebarAdmin  />
  </v-container>
</template>

<script>
  import SidebarAdmin from '@/components/admin/SidebarAdmin'
  import { mapGetters } from 'vuex'
  export default {
    components: { SidebarAdmin },
    computed: {
      ...mapGetters([
        'getUser',
      ])
    }
  }
</script>

<style lang="scss">

</style>
