import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router"
import cks from '@/logic/cookies'

Vue.use(Vuex);

// axios.defaults.baseURL = "http://localhost:8000/api";

const initialState = () => ({
  token: cks.getToken(),
  user: cks.getUser(),
  choiceRole: [],
  usuarios:[],
  loadingStatus: false,
  noticias: [],
  noticia: [],
  comments: [],
  groups: [],
  courses: [],
  listUsers:[],
  teachers: [],
  roles: [],
  categories: [],
  lectivos: [],
  cursadas: [],
  inscriptions: [],
  pagos: [],
  alumnoCursadas: [],
  divisions: [],
  teachersCourses: [],
  teachersCoursesInscriptions: [],
  notes: [],
})

export default new Vuex.Store({
  state: initialState(),

  mutations: {
    RESET(state) {
      cks.clearCookies()
      Object.assign(state, initialState())
    },

    SET_USER(state, data) {
      state.user = data
      cks.setUser(data)
    },
    SET_TOKEN(state, data) {
      state.token = data
      cks.setToken(data)
    },
    SET_CHOICE_ROLE(state, data) {
      state.choiceRole = data
      cks.setRole(data)
    },
    SET_LOADING(state, data) {
      state.loadingStatus = data
    },
    SET_NOTICIAS(state, data) {
      state.noticias = data
    },
    SET_NOTICIA(state,data) {
      state.noticia = data
    },
    SET_COMMENTS(state,data) {
      state.comments = data
    },
    SET_GROUPS(state,data) {
      state.groups = data
    },
    SET_COURSES(state,data) {
      state.courses = data
    },
    SET_LISTUSERS(state,data) {
      state.listUsers = data
    },
    SET_TEACHERS(state,data) {
      state.teachers = data
    },
    SET_ROLES(state,data) {
      state.roles = data
    },
    SET_USERS(state,data) {
      state.usuarios = data
    },
    SET_CATEGORIES(state,data) {
      state.categories = data
    },
    SET_LECTIVOS(state,data) {
      state.lectivos = data
    },
    SET_CURSADAS(state,data) {
      state.cursadas = data
    },
    SET_INSCRIPTIONS(state,data) {
      state.inscriptions = data
    },
    SET_PAGOS(state,data) {
      state.pagos = data
    },
    SET_ALUMNO_CURSADAS(state,data) {
      state.alumnoCursadas = data
    },
    SET_DIVISIONS(state,data) {
      state.divisions = data
    },
    SET_TEACHERS_COURSES(state,data) {
      state.teachersCourses = data
    },
    SET_TEACHERS_COURSES_INSCRIPTIONS(state,data) {
      state.teachersCoursesInscriptions = data
    },
    SET_NOTES(state,data) {
      state.notes = data
    }
  },

  actions: {
    register({ commit }, user) {
      commit('SET_LOADING', true)
      Vue.axios.post("register", user)
        .then(response => {
          Vue.toasted.global.success('El usuario se registro correctamente.');
           commit('SET_LOADING', false)
           this.dispatch('fetchUsers')
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
    login({ commit }, credenciales) {
      commit('SET_LOADING', true)
      Vue.axios.post("login", credenciales)
        .then(response => {
           commit('SET_CHOICE_ROLE', credenciales.role)
           commit('SET_USER', response?.data)
           commit('SET_TOKEN', response?.data?.token)
           commit('SET_LOADING', false)
           router.go()
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })

    },

    logout({ commit }) {
      Vue.axios
        .post('logout')
        .then(response => {
          commit('RESET')
          router.push({ name: 'Login' })
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
        })

    },
    refresh({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post('user/refresh')
          .then(response => {
            const token = response?.data?.data.token
            commit('SET_TOKEN', token)
            resolve(token)
          })
          .catch(error => reject(error))
      })
    },
    changePassword({ commit }, user) {
      commit('SET_LOADING', true)
      Vue.axios.post("change-password", user)
        .then(response => {
          Vue.toasted.global.success('La password se modificó correctamente.');
           commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
    ForgotPassword({ commit }, user) {
      commit('SET_LOADING', true)
      Vue.axios.post("reset-password-request", user)
        .then(response => {
          Vue.toasted.global.success('El corréo de recupero se ha enviado.');
           commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
    createNoticia({ commit }, article) {
      commit('SET_LOADING', true)
      Vue.axios.post("articles", article)
        .then(response => {
          Vue.toasted.global.success('La noticia registro correctamente.');
           commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
    deleteNotice({ commit }, notice) {
      Vue.axios.delete(`articles/${notice.id}`, notice)
        .then(response => {
          Vue.toasted.global.success('El articulo se elimino correctamente');
          this.dispatch('fetchNoticias')
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
        })
    },
    updateNotice({ commit }, notice) {
      return new Promise(resolve => {
        Vue.axios
          .put(`articles/${notice.id}`, notice)
          .then(response => {
            Vue.toasted.global.success('Datos modificados exitosamente!')
            this.dispatch('fetchNoticias')
          })
          .catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    fetchNoticias({ commit }) {
      commit('SET_LOADING', true)
      Vue.axios
        .get('articles')
        .then(response => {
          commit('SET_NOTICIAS', response?.data?.data.articles)
          commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
    fetchOneNotice({ commit } , id) {
      commit('SET_LOADING', true)
      Vue.axios
      .get(`articles/${id}`)
      .then(response => {
        commit('SET_NOTICIA', response?.data?.data)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    fetchCommentsOfArticle({ commit } , id) {
      Vue.axios
      .get(`articles/${id}/comments`)
      .then(response => {
        commit('SET_COMMENTS', response?.data?.data)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.errors)
      })
    },
    addComment(context, comentario ) {
      Vue.axios
        .post(`articles/${comentario.id}/comments`, comentario )
        .then(response => {
          Vue.toasted.global.success('El comentario se agrego correctamente');
          this.dispatch('fetchCommentsOfArticle', comentario.id)
        })
        .catch(error => {
          this._vm.$toastErrors(error.response?.data?.message)
        })
    },
    deleteComment({ commit }, comment) {
      Vue.axios.delete(`comments/${comment.id}`, comment)
        .then(response => {
          Vue.toasted.global.success('El comentario se elimino correctamente');
          this.dispatch('fetchCommentsOfArticle', comment.article)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
        })
    },
    sendContact(context, contact ) {
      Vue.axios
        .post('contact', contact )
        .then(response => {
          Vue.toasted.global.success('En breve nos comunicaremos con usted.');
        })
        .catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
        })
    },
    fetchGroups({ commit }) {
      commit('SET_LOADING', true)
      Vue.axios
      .get('category')
      .then(response => {
        commit('SET_GROUPS', response?.data?.data?.category)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    fetchListCourses({commit}, id) {
      commit('SET_LOADING', true)
      Vue.axios
      .get(`category/${id}`)
      .then(response => {
        commit('SET_COURSES', response?.data?.data)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    createCourse({ commit }, course) {
      commit('SET_LOADING', true)
      Vue.axios.post("course", course)
        .then(response => {
          Vue.toasted.global.success('El curso se registro correctamente.');
          this.dispatch('fetchCourses')
           commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
    fetchListUsers({commit}) {
      Vue.axios
      .get('user')
      .then(response => {
        commit('SET_LISTUSERS', response?.data?.data)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
      })
    },
    fetchTeachers({commit}) {
      Vue.axios
      .get('teachers')
      .then(response => {
        commit('SET_TEACHERS', response?.data?.data.teachers)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
      })
    },
    fetchRoles({commit}) {
      Vue.axios
      .get('roles')
      .then(response => {
        commit('SET_ROLES', response?.data?.data.roles)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
      })
    },

    fetchUsers({commit}) {
      commit('SET_LOADING', true)
      Vue.axios
      .get('users')
      .then(response => {
        commit('SET_USERS', response?.data?.data?.usuarios)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    fetchDeleteUser({ commit }, user) {
      Vue.axios.delete(`user/${user.id}`, user)
        .then(response => {
          Vue.toasted.global.success('El usuario se elimino correctamente');
          this.dispatch('fetchUsers')
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
        })
    },
    updateUser({ commit }, user) {
      return new Promise(resolve => {
        Vue.axios
          .put(`user/${user.id}`, user)
          .then(response => {
            Vue.toasted.global.success('Datos modificados exitosamente!')
            this.dispatch('fetchUsers')
          })
          .catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    updateUserSelf({ commit }, usuario) {
      commit('SET_LOADING', true)
      return new Promise(resolve => {
        Vue.axios
          .post('user/perfil', usuario)
          .then(response => {
            commit('SET_USER', response?.data)
            Vue.toasted.global.success('Sus datos fueron modificados exitosamente!')
            commit('SET_LOADING', false)
          })
          .catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
            commit('SET_LOADING', false)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    createCategory({ commit }, category) {
      commit('SET_LOADING', true)
      Vue.axios.post("category", category)
        .then(response => {
          Vue.toasted.global.success('La categoría se registro correctamente.');
          this.dispatch('fetchCategories')
           commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
    fetchCategories({commit}) {
      commit('SET_LOADING', true)
      Vue.axios
      .get('category')
      .then(response => {
        commit('SET_CATEGORIES', response?.data?.data.category)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    updateCategory({ commit }, category) {
      return new Promise(resolve => {
        Vue.axios
          .put(`category/${category.id}`, category)
          .then(response => {
            Vue.toasted.global.success('Datos modificados exitosamente!')
            this.dispatch('fetchCategories')
          })
          .catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    deleteCategory({ commit }, category) {
      Vue.axios.delete(`category/${category.id}`)
        .then(response => {
          Vue.toasted.global.success('La categoría se elimino correctamente');
          this.dispatch('fetchCategories')
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
        })
    },

    fetchCourses({commit}) {
      Vue.axios
      .get('course')
      .then(response => {
        commit('SET_COURSES', response?.data?.data.courses)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
      })
    },
    updateCourse({ commit }, course) {
      return new Promise(resolve => {
        Vue.axios
          .put(`course/${course.id}`, course)
          .then(response => {
            Vue.toasted.global.success('Datos modificados exitosamente!')
            this.dispatch('fetchCourses')
          })
          .catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    deleteCourse({ commit }, course) {
      Vue.axios.delete(`course/${course.id}`)
        .then(response => {
          Vue.toasted.global.success('El curso se elimino correctamente');
          this.dispatch('fetchCourses')
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
        })
    },
    createLectivo({ commit }, lectivo) {
      commit('SET_LOADING', true)
      Vue.axios.post("lectivo", lectivo)
        .then(response => {
          Vue.toasted.global.success('El año lectivo se registro correctamente.');
          this.dispatch('fetchLectivo')
           commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
    fetchLectivo({commit}) {
      Vue.axios
      .get('lectivo')
      .then(response => {
        commit('SET_LECTIVOS', response?.data?.data.lectivos)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
      })
    },



    createCursada({ commit }, cursada) {
      return new Promise(resolve => {
        commit('SET_LOADING', true)
        Vue.axios.post("cursada", cursada)
          .then(response => {
            Vue.toasted.global.success('La cursada se registro correctamente.');
            this.dispatch('fetchCursadas')
            commit('SET_LOADING', false)
          }).catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
            commit('SET_LOADING', false)
          })
          .finally(() => {
            resolve()
          })
      })
    },

    fetchCursadas({commit}) {
      commit('SET_LOADING', true)
      Vue.axios
      .get('cursada')
      .then(response => {
        commit('SET_CURSADAS', response?.data?.data?.cursadas)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    deleteCursada({ commit }, cursada) {
      Vue.axios.delete(`cursada/${cursada.id}`)
        .then(response => {
          Vue.toasted.global.success('La cursada se elimino correctamente');
          this.dispatch('fetchCursadas')
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
        })
    },
    updateCursada({ commit }, cursada) {
      return new Promise(resolve => {
        Vue.axios
          .put(`cursada/${cursada.id}`, cursada)
          .then(response => {
            Vue.toasted.global.success('La cursada fue modificada exitosamente!')
            this.dispatch('fetchCursadas')
          })
          .catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    createInscription({ commit }, inscription) {
      commit('SET_LOADING', true)
      Vue.axios.post("inscription", inscription)
        .then(response => {
          Vue.toasted.global.success('La inscripción se registro correctamente.');
          this.dispatch('fetchInscriptions')
           commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },

    fetchInscriptions({commit}) {
      commit('SET_LOADING', true)
      Vue.axios
      .get('inscription')
      .then(response => {
        commit('SET_INSCRIPTIONS', response?.data?.data?.inscription)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    updateInscription({ commit }, inscription) {
      return new Promise(resolve => {
        Vue.axios
          .put(`inscription/${inscription.id}`, inscription)
          .then(response => {
            Vue.toasted.global.success('Datos modificados exitosamente!')
            this.dispatch('fetchInscriptions')
          })
          .catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    deleteInscription({ commit }, inscription) {
      Vue.axios.delete(`cursada/${inscription.id}`)
        .then(response => {
          Vue.toasted.global.success('La inscripción se elimino correctamente');
          this.dispatch('fetchInscriptions')
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
        })
    },
    fetchPagos({commit}) {
      commit('SET_LOADING', true)
      Vue.axios
      .get('pago')
      .then(response => {
        commit('SET_PAGOS', response?.data?.data?.pagos)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    createCuotas({ commit }, cron_token) {
      return new Promise(resolve => {
        commit('SET_LOADING', true)
        Vue.axios.post("cuotas", cron_token)
          .then(response => {
            Vue.toasted.global.success('La cuotas se han generado correctamente');
            commit('SET_LOADING', false)
          }).catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
            commit('SET_LOADING', false)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    updatePago({ commit }, pago) {
      return new Promise(resolve => {
        Vue.axios
          .put(`pago/${pago.id}`, pago)
          .then(response => {
            Vue.toasted.global.success('Se actualizó correctamente el pago!')
            this.dispatch('fetchPagos')
          })
          .catch(error => {
            this._vm.$toastErrors(error.response?.data?.data?.errors)
          })
          .finally(() => {
            resolve()
          })
      })
    },
    fetchAlumnoCursadas({commit}) {
      Vue.axios
      .get('user/cursadas')
      .then(response => {
        commit('SET_ALUMNO_CURSADAS', response?.data?.data?.cursadas)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
      })
    },
    updateUserImage({ commit }, avatar) {
      commit('SET_LOADING', true)
      Vue.axios.post("user/perfil/avatar", avatar)
        .then(response => {
          Vue.toasted.global.success('El avatar se actualizo correctamente.');
          commit('SET_USER', response?.data)
           commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
    fetchDivisions({commit}, consulta ) {
      Vue.axios
        .post('divisions', consulta )
        .then(response => {
          commit('SET_DIVISIONS', response?.data?.data?.divisiones);
        })
        .catch(error => {
          this._vm.$toastErrors(error.response?.data?.message)
        })
    },
    fetchTeachersCourses({commit}) {
      commit('SET_LOADING', true)
      Vue.axios
      .get('teachers/courses')
      .then(response => {
        commit('SET_TEACHERS_COURSES', response?.data?.data?.cursadas)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    //devuelve los inscriptos en una cursada, lo uso un profesor
    fetchTeachersCoursesInscriptions({commit}, cursada) {
      commit('SET_LOADING', true)
      Vue.axios
      .get(`teachers/${cursada.id}/inscriptions`)
      .then(response => {
        commit('SET_TEACHERS_COURSES_INSCRIPTIONS', response?.data?.data?.inscriptions)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    fetchNotes({commit}, inscription) {
      commit('SET_LOADING', true)
      Vue.axios
      .get(`cursada/${inscription.id}/notes`)
      .then(response => {
        commit('SET_NOTES', response?.data?.data?.notes)
        commit('SET_LOADING', false)
      }).catch(error => {
        this._vm.$toastErrors(error.response?.data?.data?.errors)
        commit('SET_LOADING', false)
      })
    },
    updateNotes({ commit }, note) {
      commit('SET_LOADING', true)
      Vue.axios
          .put(`note/${note.inscription_id}`, note)
        .then(response => {
          Vue.toasted.global.success('La nota se actualizo correctamente.');
           commit('SET_LOADING', false)
        }).catch(error => {
          this._vm.$toastErrors(error.response?.data?.data?.errors)
          commit('SET_LOADING', false)
        })
    },
  },

  getters: {
    isAdmin: state => {
      return state.user.roles.includes('Admin');
    },
    isAlumno: state => {
      return state.user.roles.includes('Alumno');
    },
    getUser: state => {
      return state.user
    },
    getNoticias: state => {
      return state.noticias
    },
    getOneNotice: state => {
      return state.noticia
    },
    getLoadingStatus: state => {
      return state.loadingStatus
    },
    getCommentsOfArticle: state => {
      return state.comments
    },
    getGroups: state => {
      return state.groups
    },
    getCourses: state => {
      return state.courses
    },
    getRoles: state => {
      return state.roles
    },
    getUsers: state => {
      return state.usuarios
    },
    getCategories: state => {
      return state.categories
    },
    getLectivos: state => {
      return state.lectivos
    },
    getCursadas: state => {
      return state.cursadas
    },
    getInscriptions: state => {
      return state.inscriptions
    },
    getPagos: state => {
      return state.pagos
    },
    getAlumnoCursadas: state => {
      return state.alumnoCursadas
    },
    getChoiceRole: state => {
      return state.choiceRole
    },
    getDivisions: state => {
      return state.divisions
    },
    getTeachers: state => {
      return state.teachers
    },
    getTeachersCourses: state => {
      return state.teachersCourses
    },
    getTeachersCoursesInscriptions: state => {
      return state.teachersCoursesInscriptions
    },
    getNotes: state => {
      return state.notes
    }
  },
});
