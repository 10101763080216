<template>
  <div>
    <v-col
      cols="12"
      lg="12"
      md="12"
      sm="12"
      xl="12"
      xs="12">
      <h3 class="text-center mx-10 my-10">Noticia {{'#' + this.id}}</h3>
      <div
        v-if="loadingStatus"
        class="div-loader mb-16">
        <scale-loader
          :color="color"
          :size="size"></scale-loader>
      </div>
      <v-row
        v-else
        class="mb-10"
        justify="center">
        <v-col
          cols="10"
          lg="10"
          md="10"
          sm="10"
          xl="10"
          xs="10">
          <v-card
            class="pb-16"
            min-height="300">
            <v-card-title>{{oneNotice.title}}</v-card-title>
            <div class="contenedor-img">
              <img
                class="img-articles"
                :src="url +'/'+ this.id + '/image' ">
              <v-card-subtitle
                class="text-justify"
                min-width="300"
                v-html="oneNotice.body"> </v-card-subtitle>
            </div>
            <div class="div-user text-right mr-5 pb-2 mt-15">
              <p style="margin-bottom:2px; color:#F2AA9A;">{{oneNotice.user.name}}</p>
              <p>{{format(oneNotice.created_at)}}</p>
            </div>

          </v-card>
        </v-col>
      </v-row>
    </v-col>


    <v-col>

      <v-row
        align="center"
        class="mb-15"
        justify="center">
        <p
          v-if="!getUser"
          class="red--text">¡Para ingresar un comentario debe ingresar con su usuario!</p>
        <v-form
          v-if="getUser"
          ref="commentForm"
          lazy-validation>
          <v-textarea
            v-model="comentario.text"
            :counter="400"
            label="Su comentario"
            name="input-7-4"
            outlined
            required
            :rules="textRules"
            style="width:500px;"
            value="" />
          <v-row>
            <v-btn
              class="mt-5 ml-5"
              color="primary"
              :loading="busy"
              rounded
              variant="dark"
              x-large
              @click.prevent="submitForm()">
              Enviar
            </v-btn>
          </v-row>
        </v-form>
      </v-row>
      <v-data-table
        v-if="this.comments"
        class="elevation-1 mx-10"
        :headers="headers"
        :items="this.comments">
        <template
          v-slot:item.actions="{ item }">
          <h6
            class="px-3 py-3">{{item.user_data.name}}
          </h6>
          <p>{{item.text}}</p>
          <p
            class="text-right">{{format(item.created_at)}}</p>
          <p
            v-if="(getUser && isAdmin) || getUser.usuario.id === item.user_data.id"
            class="float-right">
            <v-icon
              small
              @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </p>
        </template>
        <template slot="no-data">
          <p>Podrías ser el primer en comentar esta noticia ¡adelante!</p>
        </template>
      </v-data-table>
      <!-- <v-row
        v-for="comment in comments"
        :key="comment.texto"
        align="center"
        justify="center">
        <v-card
          class="px-5 my-5"
          max-width="500"
          min-height="60"
          min-width="500">
          <div class="flex flex-wrap mt-3">
            <h6
              class="ml-2"
              style="width:90%;float:left">{{comment.user_data.name}}
            </h6>
            <v-icon
              class="text-right"
              small
              @click="deleteItem(comment.id)">
              mdi-delete
            </v-icon>
          </div>
          <v-card-text>{{comment.text}}</v-card-text>
        </v-card>
      </v-row> -->
    </v-col>


  </div>
</template>

<script>
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import moment from 'moment'
  export default {
    data() {
      return {
        headers: [

          { text: 'Listado de comentarios', value: 'actions', sortable: false },
        ],

        editedIndex: -1,
        editedItem: {},
        text:'',
        comentario:{
          id:'',
          text:'',
        },
        busy:false,
        color: '#cc181e',
        color1: '#5bc0de',
        size: '70px',
        margin: '2px',
        radius: '2px',

        id: this.$route.params.id,
        url: 'https://instituto-api.herokuapp.com/api/articles',

        textRules: [
          (v) => !!v || "Este campo es requerido",
          // (v) => v.length <= 400 || "No puede pasar de 400 dígitos",
        ],
      }
    },
    components: {
      ScaleLoader,
    },
    methods: {
      async deleteItem (item) {
        let res = await this.$confirm('¿Quiere borrar este comentario?', { title: 'Comentarios' })
        if (res) {
          this.$store.dispatch('deleteComment', item)
        }
      },
      format(fecha) {
        return moment(fecha).utc().format('DD/MM/YYYY')
      },
      submitForm() {
        if (this.$refs.commentForm.validate()) {
          this.busy = true
          this.comentario.id = this.id
          this.$store
            .dispatch('addComment', this.comentario)
            .then(() => {
            })
            .catch(error => {
              this.$toastErrors(error)
            })
            .finally(() => {
              this.busy = false
            })
        } else {
          return false
        }
      },
    },
    computed: {
      isAdmin() {
        return this.$store.getters.isAdmin;
      },
      oneNotice() {
        return this.$store.getters.getOneNotice
      },
      comments() {
        return this.$store.getters.getCommentsOfArticle
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      getUser() {
        return this.$store.getters.getUser
      },
    },
    created() {
      this.$store.dispatch('fetchOneNotice', this.id)
      this.$store.dispatch('fetchCommentsOfArticle', this.id)

    }
  }
</script>

<style>

</style>