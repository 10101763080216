<template>
  <main>
    <v-container
      class="login"
      fill-height
      fluid>
      <v-layout
        align-center
        flex
        justify-center>
        <v-flex
          elevation-6
          lg3
          md4
          sm6
          xs12>
          <v-toolbar class="pt-5 orange lighten-4">
            <v-toolbar-items>
              <v-toolbar-title class="white--text">
                <h4>{{title}}</h4>
              </v-toolbar-title>
            </v-toolbar-items>
          </v-toolbar>
          <v-card>
            <v-card-text
              class="pt-13">
              <div>
                <v-form
                  ref="form"
                  v-model="valid">
                  <v-text-field
                    v-model="email"
                    :append-icon="'account_circle'"
                    label="Ingrese su Email"
                    required
                    :rules="emailRules" />

                  <v-select
                    v-if="type!=1"
                    v-model="choiceRole"
                    item-text="name"
                    item-value="value"
                    :items="roles"
                    label="Elegir su Rol"
                    :rules="[v => !!v || 'Este item es requerido']" />

                  <v-text-field
                    v-if="type!=1"
                    v-model="password"
                    :append-icon="e1 ? 'visibility' : 'visibility_off'"
                    counter
                    label="Ingrese su contraseña"
                    min="8"
                    required
                    :rules="passwordRules"
                    :type="e1 ? 'password' : 'text'"
                    @click:append="() => (e1 = !e1)"
                    v-on:keyup.enter="submit()" />
                  <v-divider />
                  <v-btn
                    :class=" { 'btn-login' : valid, disabled: !valid }"
                    :loading="loadingStatus"
                    width="280"
                    @click="submit">
                    Login
                  </v-btn>
                  <v-row
                    class="my-4 text-center"
                    justify-space-beetwen>
                    <a
                      v-if="type!=0"
                      href="javascript:void(0)"
                      @click="type=0">Iniciar Sesión</a>

                    <a
                      v-if="type!=1"
                      href="javascript:void(0)"
                      @click="type=1">Olvidé mi contraseña</a>

                  </v-row>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </main>
</template>

<script>
  export default {
    data() {
      return {
        choiceRole: '',
        roles: [
          {name: 'Administrador', value: "Admin"},
          {name: 'Profesor', value: "Profesor" },
          {name: 'Alumno', value: "Alumno" },
        ],
        type:0,
        valid: false,
        e1: true,
        password: "",
        passwordRules: [
          (v) => !!v || "La contraseña es requerida",
        ],
        email: "",
        emailRules: [
          (v) => !!v || "El mail es requerido",
          // eslint-disable-next-line no-useless-escape
          (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || "No posee formato de mail"
        ],
      };
    },
    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      title(){
        return (this.type==0)?'Iniciar Sesión':'Recuperar Contraseña';
      }

    },
    methods: {
      async submit() {
        const credenciales = {
          email: this.email,
          password: this.password,
          role: this.choiceRole
        };
        if (this.$refs.form.validate()) {
          if(this.type==0) {

            this.$store.dispatch("login", credenciales);
          } else {
            let res = await this.$confirm('Se le enviará un correo de recuperación de contraseña', { title: 'Recupero' })
            if (res) {
              this.$store.dispatch('ForgotPassword', credenciales)
            }
          }
        }
      },
      clear() {
        this.$refs.form.reset();
      }
    },
  };
</script>
<style lang="scss" scoped>
  .login {
    width: 100%;
    height: 100vh;
  }
 .v-text-field{
      width: 90%;
 }
 .btn-login {
   border:2px primary solid;

   &:hover {
     background-color:$naranja-claro;
   }
 }

</style>
