<template>
  <v-menu
    bottom
    offset-y
    open-on-hover
    right
    transition="slide-x-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        v-bind="attrs"
        v-on="on">
        <v-flex
          lg12
          md1
          sm2
          xs4>
          <v-avatar
            class="ml-2"
            size="36px">
            <img
              alt="Avatar"
              :src="user.avatar">
          </v-avatar>
          <v-icon>mdi-chevron-down</v-icon>
        </v-flex>
      </v-btn>
    </template>
    <v-list
      dense
      min-width="200">
      <v-list-item
        v-for="(item, index) in typeUser()"
        :key="index"
        router
        :to="{name: item.link }">
        <v-list-item-action>
          <v-list-item-title> <v-icon>{{item.icon}}</v-icon> {{ item.title }}</v-list-item-title>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import cks from '@/logic/cookies'

  export default {
    props: {
      user: {
        type: Object,
        default: null
      },
    },
    data: () => ({
      menu_user_alumno: [
        {
          icon:"mdi-home-circle",
          title: "Inicio",
          link: "Home"
        },
        {
          icon: "mdi-account",
          title: "Perfil",
          link: "AlumnoPerfil",
        },
        {
          icon: "mdi-cog-outline",
          title: "Panel",
          link: "AlumnoWelcome",
        },
        {
          icon: "mdi-logout",
          title: "Salir",
          link: "Logout",
        },
      ],
      menu_user_profesor: [
        {
          icon:"mdi-home-circle",
          title: "Inicio",
          link: "Home"
        },
        {
          icon: "mdi-account",
          title: "Perfil",
          link: "TeacherPerfil",
        },
        {
          icon: "mdi-cog-outline",
          title: "Panel",
          link: "TeacherWelcome",
        },
        {
          icon: "mdi-logout",
          title: "Salir",
          link: "Logout",
        },
      ],
      menu_user_admin: [
        {
          icon:"mdi-home-circle",
          title: "Inicio",
          link: "Home"
        },
        {
          icon: "mdi-account",
          title: "Perfil",
          link: "AdminPerfil",
        },
        {
          icon: "mdi-cog-outline",
          title: "Panel",
          link: "AdminWelcome",
        },
        {
          icon: "mdi-logout",
          title: "Salir",
          link: "Logout",
        },
      ]
    }),
    methods: {
      typeUser() {
        const role = cks.getRole()
        if(role == 'Admin') {
          return this.menu_user_admin
        } else {
          if(role == 'Profesor') {
            return this.menu_user_profesor
          } else {
            return this.menu_user_alumno
          }
        }
      }
    }
  }
</script>

<style>

</style>