<template>
  <v-footer
    :padless="padless">
    <v-card
      class="durazno"
      width="100%">
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="text-center"
            cols="12"
            lg="2"
            md="6"
            sm="6">
            <img
              alt=""
              class="mt-6"
              src="../assets/logo_gris.svg">
          </v-col>
          <v-divider
            class="mr-5 "
            color="white"
            vertical />
          <v-col
            align-self="center"
            cols="12"
            justify="center"
            lg="1"
            md="4"
            sm="4">
            <p
              class=" text-caption
               text-sm-body-2
               text-md-body-1
               text-lg-body-1
               text-xl-body-1
                gray--text text--lighten-3 text-sm-left text-xs-left text-lg-left text-center mt-md-4 mt-sm-4 mt-lg-4 mt-n8">
              Una opción diferente
            </p>
          </v-col>

          <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center text-lg-right pt-3">
            <a
              v-for="(i_foo,item) in items_footer_1"
              :key="item"
              :href="i_foo.ref">{{ i_foo.text }} <br></a>
          </div>

          <div
            align="center"
            class="col-6 col-lg-2 col-md-6 col-sm-6 col-xs-6 text-center text-lg-right pt-3 mb-8"
            justify="center">
            <a
              v-for="(i_foo,item) in items_footer_2"
              :key="item"
              :href="i_foo.ref">{{ i_foo.text }} <br></a>
          </div>
        </v-row>
      </v-card-text>

      <v-divider class="mx-15" />
      <v-row
        no-gutters
        style="background-color:black">
        <v-col
          class="pl-6"
          cols="12 "
          lg="6">
          <v-card-text class="gray--text text--lighten-3 text-lg-left text-sm-center text-md-left text-center">
            {{ new Date().getFullYear() }} — <strong> Instituto de Lenguas y Arte</strong>
          </v-card-text>
        </v-col>
        <v-col
          class=""
          cols="12 "
          lg="6">
          <v-card-text class="text-lg-right text-sm-center text-md-right text-center">
            <a
              v-for="(red,item) in url"
              :key="item"
              class="pl-6 gray--text text--lighten-3"
              :href="red.item"><font-awesome-icon  :icon="['fab', red.icon]" /></a>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>

  export default {
    name: 'FooterHome',
    data: () => ({
      valid:true,
      firstname: "",
      nameRules: [],
      suscription:'',
      emailRules: [
        (v) => !!v || "Email es requerido",
        (v) => /.+@.+/.test(v) || "El Email debe ser valido"
      ],
      items_footer_1: [
        {
          text: "Nuestra Institución",
          ref: "/element/institucion"
        },

        {
          text: "Empresas",
          ref:"/enterprises"
        },
        {
          text: "Instituciones Educativas",
          ref:"/institutions"
        }
      ],
      items_footer_2: [
        {
          text: "Servicios",
          ref: "/servicios"
        },
        {
          text: "Contactanos",
          ref: "/contactanos"
        },
        {
          text: "Noticias",
          ref: "/noticias"
        },
        {
          text: "Cursos",
          ref: "/courses"
        },


      ],
      url: [
        {
          icon: "facebook",
          item: "https://www.facebook.com/LyAinstitutodelenguasyarte"
        },
        {
          icon: "instagram-square",
          item: "https://www.instagram.com/lyainstituto/"
        },
        {
          icon: "whatsapp-square",
          item: "https://api.whatsapp.com/send?phone=543624098900"
        },
      ],
      padless: true,
      variant: "default",
    }),
    methods: {
      onSubmit() {
        if (this.$refs.suscriptionForm.validate()) {
          this.$store.dispatch('suscription', this.suscription)
          this.$refs.suscriptionForm.reset()
        } else {
          this.$toastErrors('Hay errores en su formulario')
        }
      },
    },
    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
    }
  };

</script>

<style lang="scss" scoped >
.form-input.error--text,
.form-input  .error--text {
  color: rgba(255, 255, 255, 0.7) !important;
  caret-color: rgb(2, 0, 0) !important;
}

.blue-lighten-3 {
  background-color:#4E7EAD !important;
}
  .colior {
    background-color: black;
  }
</style>
