<template>
  <body>
    <section id="main">
      <!-- todo el codigo -->
      <footer>
        Codigo del footer
      </footer>
    </section>
  </body>
</template>

<script>
  export default {

  };
</script>

<style >
body{
    /* min-height: 150%; */
    top: 0;
    margin:0;
    padding:0;
}

footer,
section#main,
body{
    width: 100%;
    position: absolute;
}

section#main{
    min-height: 100%;
}

footer{
    bottom: 0;
}
</style>
