import Vue from "vue";
import '@/logic/axios'
import '@/logic/toasted'

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import '@babel/polyfill'
import 'mutationobserver-shim'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import store from "./store";
import router from "./router";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "animate.css";

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );


import VuetifyConfirm from 'vuetify-confirm'
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Confirmar',
  buttonFalseText: 'Denegar',
  color: 'primary',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  property: '$confirm'
})


Vue.config.productionTip = false;
library.add(fas, far, fab);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
