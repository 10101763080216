

import Cookies from "js-cookie"
const TOKEN = 'token'
const USER = 'user'
const ROLE = 'role'

export default {
    setUser(data) {
        Cookies.set(USER, JSON.stringify(data))
    },

    setToken(token) {
        Cookies.set(TOKEN, token)
    },

    setRole(role) {
        Cookies.set(ROLE, role)
    },

    getUser() {
        const user = Cookies.get(USER)
        if (user) return JSON.parse(user)
    },

    getToken() {
        return Cookies.get(TOKEN)
    },

    getRole() {
        return Cookies.get(ROLE)
    },

    clearCookies() {
        Cookies.remove(USER)
        Cookies.remove(TOKEN)
        Cookies.remove(ROLE)
    },

    isLogged() {
        return !!this.getToken() && !!this.getUser()
    }
}
