<template>
  <v-data-table
    class="elevation-1"
    :expanded.sync="expanded"
    :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
    :headers="headers"
    item-key="id"
    :items="this.teachersCoursesInscriptions"
    :loading="loadingStatus"
    loading-text="Cargando...por favor aguarde"
    :search="search"
    show-expand
    :single-expand="singleExpand"
    @item-expanded="loadNotes">
    <template v-slot:top>
      <v-toolbar
        flat
        height="150px">
        <v-row class="flex flex-space-between">
          <v-col
            cols="12"
            lg="6">
            <v-toolbar-title>Lista de Inscripciones del {{ new Date().getFullYear() }}</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical></v-divider>
            <v-spacer></v-spacer>
          </v-col>
          <v-col
            cols="12"
            lg="6">
            <v-select
              v-model="cursada"
              autocomplete
              bottom
              item-text="courses.name"
              item-value="id"
              :items="teachersCourses.filter(courses => courses.lectivo.ano === new Date().toISOString().substr(0, 4))"
              label="Elige una cursada"
              :rules="[v => !!v || 'La cursada es requerido']"
              style="width:300px;"
              v-on:change="filterCoursesInscriptions"></v-select>
            <v-dialog
              v-model="dialog"
              class="text-left"
              max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text
                  class="mt-7">
                  <v-form
                    ref="courseForm"
                    v-model="valid"
                    lazy-validation>

                    <!-- aca hay que seguir -->

                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    v-if="editedIndex !== -1"
                    color="blue darken-1"
                    text
                    @click="save">
                    Guardar
                  </v-btn>
                  <v-btn
                    v-else
                    class="mr-4"
                    color="success"
                    :disabled="!valid"
                    :loading="loadingStatus"
                    @click="validate()">
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px">
              <v-card>
                <v-card-title class="headline">¿Esta seguro de eliminar este curso?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete">Cancel</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers }">
      <td :colspan="headers.length">
        <div class="d-flex justify-space-around my-4">
          <div class="mt-10">
            <Notas :note="notes" />
          </div>
        </div>

      </td>
    </template>
    <template slot="no-data">
      <p>Por el momento no hay inscriptos</p>
    </template>
  </v-data-table>
</template>

<script>
  import Notas from "@/components/teacher/components/Notas.vue";
  export default {
    components: {
      Notas
    },
    data: () => ({
      miItem: '',
      cursada: '',
      valid: true,
      expanded: [],
      singleExpand: true,
      search: '',
      date: null,
      menu: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Alumnno', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: '', value: 'data-table-expand' },
      ],

      editedIndex: -1,
      editedItem: {
        id: '',
        cursada_id:'',
        cursada: {
          id:'',
          price:'',
        },
        user_id:'',
        user: {
          id:'',
          name:'',
        },
        becado:'',
      },
      defaultItem: {
        id: '',
        cursada_id:'',
        cursada: {
          id:'',
          price:'',
        },
        user_id:'',
        user: {
          id:'',
          name:'',
        },
        becado:'',
      },
      nameRules: [
        value => !!value || 'Campo requerido',
        value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 40) || 'Máximo 40 caracter',
      ],
    }),

    computed: {
      notes() {
        return this.$store.getters.getNotes
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      teachersCourses() {
        return this.$store.getters.getTeachersCourses
      },
      teachersCoursesInscriptions() {
        return this.$store.getters.getTeachersCoursesInscriptions
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Inscripción' : 'Editar Inscipción'
      },
      cursadasCurrent() {
        let current = this.teachersCourses.filter(courses => courses.lectivo.ano === new Date().toISOString().substr(0, 4) );
        return current;
      },

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.$store.dispatch('fetchTeachersCourses')
    },

    methods: {
      loadNotes({item}) {
        const data = {
          id: item.pivot.id
        }
        this.$store.dispatch('fetchNotes', data )
      },
      filterCoursesInscriptions() {
        const data = {
          id: this.cursada
        }
        this.$store.dispatch('fetchTeachersCoursesInscriptions', data)
      },
      customFilter (item, queryText, itemText) {
        const textOne = item.name.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1
      },
      editItem (item) {
        this.editedIndex = this.teachersCoursesInscriptions.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.$store.dispatch('deleteInscription', this.editedItem)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          this.$store.dispatch('updateInscription', this.editedItem)
        }
        this.close()

      },
      //validar formulario
      validate () {
        if(this.$refs.courseForm.validate()) {
          this.$store.dispatch('createCourse', this.editedItem)
          this.$refs.courseForm.reset()
          this.close()
        }
      },
    },
  }
</script>

<style scoped>
  .v-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }
</style>>

