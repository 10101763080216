<template>
  <v-main>
    <v-container
      class="numeros mt-3 mb-n5"
      fill
      fluid>
      <v-flex>
        <v-row>
          <v-col cols="12">
            <p class="text-h4 text-center mt-12 mb-3">
              Servicios
            </p>
          </v-col>
        </v-row>
      </v-flex>
      <v-layout
        fill
        flex-wrap>
        <v-flex
          v-for="(servicio,i) in servicios"
          :key="i"
          class="mt-13 mb-13"
          fill>
          <v-hover
            v-slot="{ hover }"
            open-delay="200">
            <v-card
              class="mx-auto pt-1 rounded-card "
              :class="{ 'on-hover': hover }"
              :elevation="hover ? 16 : 2"
              height="500"
              width="350">
              <v-card-title>
                <p class="h4 mx-auto">
                  {{ servicio.tittle }}
                </p>
              </v-card-title>
              <v-row
                class="text-center"
                justify="space-around">
                <v-flex
                  fill
                  justify="center">
                  <v-avatar
                    color="gray"
                    size="200">
                    <v-img
                      alt="John"
                      :src="servicio.img" />
                  </v-avatar>
                </v-flex>
              </v-row>
              <v-divider
                class="mx-auto mt-10"
                width="80%" />
              <v-card-text class="text-center  px-8">
                <p
                  class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify">
                  {{ servicio.text }}
                </p>
              </v-card-text>

              <v-btn
                class="btn-knows primary"
                width="100%"
                @click="goTo( servicio.href )">
                SABER MÁS
              </v-btn>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
  const cursos = require("../assets/serv-cursos.jpg");
  const empresas = require("../assets/serv-emp.jpg");
  const college = require("../assets/serv-college.jpg");

  export default {
    data: () => ({
      servicios: [
        {
          tittle: "Cursos",
          img: cursos,
          text: "La mejor inversión es la educación y una de las llaves del mundo es estudiar Inglés. Nosotros tenemos un curso a tu medida.",
          href: "/courses"
        },
        {
          tittle: "Instituciones Educativas",
          img: college,
          text: "La organización de una instutición puede ser complicada y nosotros podemos aportar nuestra experiencia.",
          href: "/institutions"
        },
        {
          tittle: "Empresas",
          img: empresas,
          text: "El inglés cambia la vida de las personas y también de las empresas en donde se desarrollan.",
          href: "/enterprises"
        },
      ]
    }),
    methods: {
      goTo(href) {
        this.$router.push(href);
      }
    }
  };
</script>

<style>
  .numeros {
    background-image: url('~@/assets/pattern.jpg');
    background-repeat: repeat;
  }
 @media screen and (max-width: 991px) {
   .numeros {
    min-height: 1400px;
   }
}
 @media screen and (min-width: 550px) {
   .numeros {
     min-height: 800px;
   }
}
.rounded-card {
  border-radius: 6% !important;
}
.btn-knows {
  position: absolute;
  bottom: 0px;
}

</style>
