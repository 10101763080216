<template>
  <main>
    <v-container
      class="login"
      fill-height
      fluid>
      <v-layout
        align-center
        flex
        justify-center>
        <v-flex
          elevation-6
          lg4
          md4
          sm6
          xs12>
          <v-toolbar class="pt-5 orange lighten-4">
            <v-toolbar-items>
              <v-toolbar-title class="white--text">
                <h4>Cambio de contraseña</h4>
              </v-toolbar-title>
            </v-toolbar-items>
          </v-toolbar>
          <v-card>
            <v-card-text
              class="pt-13">
              <div>
                <v-form
                  ref="form"
                  v-model="valid">
                  <v-text-field
                    v-model="user.email"
                    :append-icon="'account_circle'"
                    label="Ingrese su Email"
                    required
                    :rules="emailRules" />
                  <v-text-field
                    v-model="user.password_confirmation"
                    :append-icon="e1 ? 'visibility' : 'visibility_off'"
                    counter
                    label="Ingrese su contraseña"
                    min="8"
                    required
                    :rules="passwordRules"
                    :type="e1 ? 'password' : 'text'"
                    @click:append="() => (e1 = !e1)" />
                  <v-text-field
                    v-model="user.password"
                    :append-icon="e1 ? 'visibility' : 'visibility_off'"
                    counter
                    label="Ingrese su confirmación"
                    min="8"
                    required
                    :rules="passwordRules"
                    :type="e1 ? 'password' : 'text'"
                    @click:append="() => (e1 = !e1)"
                    v-on:keyup.enter="changePassword()" />

                  <v-divider />
                  <v-btn
                    :class=" { 'btn-login' : valid, disabled: !valid }"
                    :loading="loadingStatus"
                    width="280"
                    @click="changePassword">
                    Cambiar
                  </v-btn>
                  <v-row
                    class="my-4 text-center"
                    justify-space-beetwen>
                    <router-link :to="{ name: 'Login'}">Iniciar Sesión</router-link>
                  </v-row>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </main>
</template>

<script>
  import Vue from 'vue'
  import router from "@/router"
  export default {
    data() {
      return {
        user: {
          passwordToken: this.$route.params.token,
          email:'',
          password:'',
          password_confirmation:'',
        },
        valid: false,
        e1: true,
        password: "",
        passwordRules: [
          (v) => !!v || "Password is required",
        ],
        email: "",
        emailRules: [
          (v) => !!v || "E-mail is required",
          // eslint-disable-next-line no-useless-escape
          (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid"
        ],
      }
    },
    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
    },
    methods: {
      changePassword() {
        if (this.user.password === this.user.password_confirmation){
          this.$store.dispatch('changePassword', this.user)
        } else {
          Vue.toasted.global.error('La password y confirmación no coinciden')
        }
      },
      clear() {
        this.$refs.form.reset();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login {
    width: 100%;
    height: 100vh;
  }
 .v-text-field{
      width: 90%;
 }
 .btn-login {
   border:2px primary solid;

   &:hover {
     background-color:$naranja-claro;
   }
 }

</style>
