<template>
  <v-container>
    <v-form
      ref="noticeForm"
      v-model="valid"
      lazy-validation>
      <v-card
        class="pt-10"
        flat
        min-height="200"
        width="350">
        <v-text-field
          v-model="article.title"
          label="Titulo de la Noticia"
          :rules="nameTitle"></v-text-field>
        <v-img
          class="primary my-5"
          height="200"
          max-width="100%"
          :src="url" />
        <v-file-input
          v-model="article.image"
          accept="image/*"
          label="Sube su imagen"
          name="image"
          :rules="[v => !!v || 'Este item es requerido']"
          type="file" />
        <v-select
          v-model="article.category"
          item-text="name"
          item-value="id"
          :items="categories"
          label="Seleccionar un Categoría"
          required
          :rules="[v => !!v || 'Este item es requerido']"></v-select>
        <ckeditor
          v-model="article.editorData"
          :config="editorConfig"
          :editor="editor"
          style="height:200px;"></ckeditor>
        <!-- <h2>Editor data</h2>

        <div v-html="article">
        </div> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="reset()">
            Cancelar
          </v-btn>
          <v-btn
            class="mr-4"
            color="success"
            :disabled="!valid"
            :loading="false"
            @click="validate()">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    name: 'app',
    data() {
      return {
        image:'',
        article: {
          title:'',
          image:null,
          category:'',
          editorData: 'El contenido del su Noticia',
        },
        imageRules: [
          value => !!value || 'Campo requerido',
        ],
        nameTitle: [
          value => !!value || 'Campo requerido',
        ],
        valid: true,
        editor: ClassicEditor,

        editorConfig: {
          // The configuration of the rich-text editor.
          toolbar: [
            ['Bold'],
            ['Italic'],
            ['Link'],
            ['Undo'],
            ['Redo'],
            ['bulletedList'],
            ['numberedList']

          ],
        }
      };
    },

    computed: {
      categories() {
        return this.$store.getters.getCategories
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      url() {
        if (!this.article.image) return;
        return URL.createObjectURL(this.article.image);
      },
    },
    created() {
      this.$store.dispatch('fetchCategories')
    },
    methods: {
      reset() {
        this.$refs.noticeForm.reset()
      },
      validate () {
        if(this.$refs.noticeForm.validate()) {
          //Creamos el formData
          var data = new  FormData();
          //Añadimos la imagen seleccionada
          data.append('title', this.article.title);
          data.append('body', this.article.editorData);
          data.append('image', this.article.image);
          data.append('category_id', this.article.category);
          //Añadimos el método PUT dentro del formData
          // Como lo hacíamos desde un formulario simple _(no ajax)_
          this.$store.dispatch('createNoticia', data)
          //   this.$refs.entryForm.reset()
          //   this.close()
        }
      },
    }
  }

</script>

<style>
.ck-editor__editable_inline {
    min-height: 150px;
}
</style>