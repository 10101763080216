var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"expanded":_vm.expanded,"footer-props":{itemsPerPageText: 'Filas por pagina:'},"headers":_vm.headers,"item-key":"id","items":_vm.cursadas,"loading":_vm.loadingStatus,"loading-text":"Cargando...por favor aguarde","search":_vm.search,"show-expand":"","single-expand":_vm.singleExpand,"sort-by":"calories"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"150px"}},[_c('v-row',{staticClass:"flex flex-space-between"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-toolbar-title',[_vm._v("Lista de Cursadas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"autocomplete":"","bottom":"","item-text":"ano","item-value":"ano","items":_vm.lectivos,"label":"Elige año Lectivo"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.estado === '1')?_c('h6',{staticClass:"green darken-1 grey--text text--lighten-5 text-center py-1 rounded"},[_vm._v(" Activo")]):_vm._e(),(item.estado === '0')?_c('h6',{staticClass:"red darken-1 grey--text text--lighten-5 text-center py-1 rounded"},[_vm._v(" Cerrado")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){return _vm.closeCursada(item)}}},[_vm._v(" mdi-close-thick ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex justify-space-around my-4"},[_c('v-card',{attrs:{"flat":"","max-width":"300"}},[_c('v-card-title',[_vm._v("Curso")]),_c('v-card-text',{staticClass:"text-justify"},[_c('p',[_c('strong',[_vm._v("Nombre:")]),_vm._v(" "+_vm._s(item.courses.name))]),_c('p',[_c('strong',[_vm._v("Descripción:")]),_vm._v(" "+_vm._s(item.courses.description))])])],1),_c('v-card',{attrs:{"flat":"","max-width":"300"}},[_c('v-card-title',[_vm._v("Año Lectivo")]),_c('v-card-text',{staticClass:"text-justify"},[_c('p',[_c('strong',[_vm._v("Año:")]),_vm._v(" "+_vm._s(item.lectivo.ano))]),_c('p',[_c('strong',[_vm._v("Inicio:")]),_vm._v(" "+_vm._s(item.lectivo.inicio))]),_c('p',[_c('strong',[_vm._v("Final:")]),_vm._v(" "+_vm._s(item.lectivo.fin))])])],1),_c('v-card',{attrs:{"flat":"","max-width":"300"}},[_c('v-card-title',[_vm._v("Profesor")]),_c('v-card-text',{staticClass:"text-justify"},[_c('p',[_c('strong',[_vm._v("ID:")]),_vm._v(" "+_vm._s(item.teacher.id))]),_c('p',[_c('strong',[_vm._v("Nombre:")]),_vm._v(" "+_vm._s(item.teacher.name))]),_c('p',[_c('strong',[_vm._v("Email:")]),_vm._v(" "+_vm._s(item.teacher.email))])])],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }