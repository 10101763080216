<template>
  <v-container>
    <v-form
      ref="cursadaForm"
      v-model="valid"
      lazy-validation>
      <v-card-text
        class="pt-10"
        min-height="200">
        <v-row>
          <v-col
            cols="0"
            lg="0"
            sm="0"
            xl="1">
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="3"
            sm="12"
            xl="2">
            <v-select
              v-model="cursada.lectivo_id"
              autocomplete
              bottom
              item-text="ano"
              item-value="id"
              :items="lectivos"
              label="Elige año Lectivo"
              :rules="[v => !!v || 'El año lectivo es requerido']"
              v-on:change="stateCourse = false"></v-select>
          </v-col>
          <v-col
            cols="12"
            lg="7"
            md="7"
            sm="12"
            xl="3">
            <v-select
              v-model="cursada.course_id"
              autocomplete
              bottom
              :disabled="stateCourse"
              item-text="name"
              item-value="id"
              :items="courses"
              label="Elige curso"
              :rules="[v => !!v || 'El curso es requerido']"
              v-on:change="getDivision"></v-select>
          </v-col>

          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            xl="2">
            <v-select
              v-model="cursada.division"
              autocomplete
              bottom
              :disabled="stateDivision"
              :items="computedItems"
              label="Elige División"
              :rules="[v => !!v || 'La división es requerida']"></v-select>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            xl="2">
            <v-text-field
              v-model="cursada.price"
              label="Precio"
              prepend-icon="mdi-cash"
              required
              :rules="numberRule"></v-text-field>
          </v-col>
        </v-row>
        <v-row>

          <v-col
            cols="0"
            lg="0"
            sm="0"
            xl="1">
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="3"
            sm="12"
            xl="2">
            <v-dialog
              ref="inicioCursada"
              v-model="modalInicio"
              persistent
              :return-value.sync="cursada.inicio"
              width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="cursada.inicio"
                  label="Inicio"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  :rules="[v => !!v || 'El inicio es requerido']"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker
                v-model="cursada.inicio"
                locale="es"
                scrollable>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="modalInicio = false">
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="$refs.inicioCursada.save(cursada.inicio)">
                  Guardar
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col
            cols="12"
            lg="3"
            md="3"
            sm="12"
            xl="2">
            <v-dialog
              ref="finCursada"
              v-model="modalFin"
              persistent
              :return-value.sync="cursada.fin"
              width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="cursada.fin"
                  label="Fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  :rules="[v => !!v || 'El fin es requerido']"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker
                v-model="cursada.fin"
                locale="es"
                scrollable>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="modalFin = false">
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="$refs.finCursada.save(cursada.fin)">
                  Guardar
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <!-- <v-col
            cols="12"
            lg="4"
            md="4"
            sm="12"
            xl="2">
            <v-select
              v-model="cursada.estado"
              autocomplete
              bottom
              item-text="state"
              item-value="valor"
              :items="estados"
              label="Elige el estado"
              :rules="[v => !!v || 'El estado es requerido']"></v-select>
          </v-col> -->
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            xl="5">
            <v-select
              v-model="cursada.teacher_id"
              autocomplete
              bottom
              item-text="name"
              item-value="id"
              :items="teachers"
              label="Elige un Profesor"
              :rules="[v => !!v || 'El profesor es requerido']"></v-select>
          </v-col>



        </v-row>

      </v-card-text>

      <v-card-actions class="">
        <v-btn
          class="px-5 py-5"
          color="primary"
          :disabled="!valid"
          :loading="loadingStatus"
          @click="validate">
          Crear
        </v-btn>
      </v-card-actions>


    </v-form>
  </v-container>

</template>

<script>
  export default {
    data: () => ({
      stateCourse: true,
      stateDivision: true,
      valid: true,
      division: ['A', 'B', 'C', 'D', 'E'],
      cursada:{
        lectivo_id:'',
        course_id:'',
        teacher_id:'',
        inicio:'',
        fin: '',
        estado:'1',
        price:'',
        division:'',
      },
      modalInicio: false,
      modalFin: false,
      // estados: [
      //   { state:'Abierto', valor: '1' },
      //   { state:'Cerrado', valor: '0' },
      // ],
      numberRule: [
        v => /^([0-9])*$/g.test(v) || 'Solo puede contener números',
        v => (v && v.length <= 5) || 'El precio debe tener menos de 5 caracteres',
      ],

    }),
    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      lectivos() {
        return this.$store.getters.getLectivos
      },
      courses() {
        return this.$store.getters.getCourses
      },
      divisions() {
        return this.$store.getters.getDivisions
      },
      teachers() {
        return this.$store.getters.getTeachers
      },
      computedItems() {
        const div = this.$store.getters.getDivisions
        return this.division.map(item => {
          return {
            text: item,
            disabled: div.includes(item)
          }
        })
      }
    },
    created() {
      this.$store.dispatch('fetchLectivo')
      this.$store.dispatch('fetchCourses')
      this.$store.dispatch('fetchTeachers')
    },
    methods: {
      getDivision() {
        const consulta = {
          lectivo_id: this.cursada.lectivo_id,
          course_id: this.cursada.course_id
        }
        this.$store.dispatch('fetchDivisions', consulta)
        this.stateDivision = false
      },
      validate () {
        if(this.$refs.cursadaForm.validate()) {
          this.$store.dispatch('createCursada', this.cursada)
        }
      },
    }
  }
</script>

<style>

</style>