<template>
  <v-container>
    <v-card
      class="mb-10"
      flat>
      <div class="d-flex flex-space-around">
        <v-img
          alt="John"
          class="mt-5 ml-5"
          :src="require('../assets/corbata.svg')"
          width="100" />
        <v-card-text class="text-left mt-10 pl-8 title"> Servicio Empresarial</v-card-text>
      </div>

      <v-card-text class="text-center  px-8">
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify">
          En la actualidad, la diversidad cultural en el mundo laboral está en su nivel más alto y muchas
          empresas se dieron cuenta de los beneficios de implementar programas de capacitación transcultural
          para sus empleados. Programas que ayudan a que tu empresa pueda expandirse internacionalmente,
          o quizás mejorar la comunicación entre equipos globales dispersos. Son por estas razones por las
          cuales Instituto L&A ofrece cursos empresariales de inglés.
        </p>
        <p
          class="text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1
                   text-justify">
          En un mundo totalmente globalizado, ofrecer un programa integral de capacitación en el idioma ingles
          a tus colaboradores permite que sean más productivos y efectivos. Los cursos de idioma ingles en el
          Instituto L&A están diseñados para satisfacer las necesidades de los profesionales. Ofrecemos
          diferentes modalidad de cursos como ser, clases privadas para personal jerárquico o cursos grupales,
          los cuales podrían ser impartidos en la misma empresa o en nuestras instalaciones, nosotros tenemos
          un paquete o plan que se puede ajustar a tus necesidades y a las de tu empresa.
        </p>
        <p class="title text-left">Instituto L&A puede ofrecerte:</p>
        <ul>
          <li
            v-for="ofrece in ofrecimiento"
            :key="ofrece.texto">
            <p
              class="text-left text-caption
                   text-sm-body-2
                   text-md-body-1
                   text-lg-body-1
                   text-xl-body-1">
              {{ ofrece.texto }}
            </p>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  const img = require("../assets/corbata.svg");
  export default {
    data: () => ({
      ofrecimiento: [
        {texto:"Una serie de programas totalmente personalizados, que se adaptarán a las necesidades de la empresa y desarrollarán las habilidades que necesita el personal para cumplir sus objetivos de acuerdo con su función y actividad de la industria."},
        {texto:" Un contenido con vocabulario específico para la rama de la industria en la que la empresa se desenvuelve."},
        {texto: " Una variedad de opciones en lo que clases se refieren, pueden ser presenciales en nuestras instalaciónes o en la misma empresa; clases en linea o mixtas para que el personal pueda aprender según sus horarios y preferencias."}
      ]
    }),
  }
</script>

<style>

</style>