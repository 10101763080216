<template>
  <v-container>
    <v-data-table
      class="elevation-1"
      :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
      :headers="headers"
      item-key="id"
      :items="teachersCourses"
      :loading="loadingStatus"
      loading-text="Cargando...por favor aguarde">
      <template v-slot:item.state="{ item }">
        <h6
          v-if="item.estado === '1'"
          class="green darken-1 grey--text text--lighten-5 text-center py-1 rounded"> Activo</h6>
        <h6
          v-if="item.estado === '0'"
          class="red darken-1 grey--text text--lighten-5 text-center py-1 rounded"> Cerrado</h6>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Año Lectivo', value: 'lectivo.ano' },
        { text: 'Cursada', value: 'courses.name' },
        { text: 'Comisión', value: 'division' },
        { text: 'Estado', value: 'state', sortable: false },
      ],
    }),
    methods: {
      state(item) {
        if(item === '0') return 'Sin pagar'
        else if(item === '1') return 'Pagado'
        else if(item === '2') return 'Anulado'
      },
    },
    computed: {
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      teachersCourses() {
        return this.$store.getters.getTeachersCourses
      }
    },
    created () {
      this.$store.dispatch('fetchTeachersCourses')
    }
  }
</script>

<style>

</style>