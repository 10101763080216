<template>
  <v-container>
    <sidebarTeacher  />
  </v-container>
</template>

<script>
  import SidebarTeacher from '@/components/teacher/SidebarTeacher'
  import { mapGetters } from 'vuex'
  export default {
    components: { SidebarTeacher },
    computed: {
      ...mapGetters([
        'getUser',
      ])
    }
  }
</script>

<style lang="scss">

</style>
