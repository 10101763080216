import Vue from "vue";
import VueRouter from "vue-router";
import cks from '@/logic/cookies'

import Home from "../views/Home.vue";
import Institutions from "../views/Institutions.vue";
import Enterprises from "../views/Enterprises.vue";
import Noticias from "@/components/Noticias.vue";
import Noticia from "@/components/Noticia.vue";
import Nosotros from "@/views/Nosotros.vue";
import Login from "@/views/Login.vue";
import FooterElement from "@/views/FooterElement.vue";
import Logout from "@/views/Logout.vue";
import Courses from "@/views/Courses.vue";
import ChangePassword from "@/views/Change-Password.vue";
import VerifyEmail from "@/views/VerifyEmail.vue";
import Cursos from "@/components/cursos/Cursos.vue";

import Admin from "@/components/admin/Admin.vue";
import AdminWelcome from "@/components/admin/AdminWelcome.vue";
import AdminAlumnos from "@/components/admin/Alumnos.vue";
import AdminCursadas from "@/components/admin/Cursadas.vue";
import AdminPagos from "@/components/admin/Pagos.vue";
import AdminSettings from "@/components/admin/Settings.vue";
import AdminNotice from "@/components/admin/Noticias.vue";
import AdminInscriptions from "@/components/admin/Inscriptions.vue";
import AdminPerfil from "@/components/admin/AdminPerfil.vue";

import Teacher from "@/components/teacher/Teacher.vue";
import TeacherWelcome from "@/components/teacher/TeacherWelcome.vue";
import TeacherPerfil from "@/components/teacher/TeacherPerfil.vue";
import TeacherCourses from "@/components/teacher/Courses.vue";

import Alumno from "@/components/alumno/Alumno.vue";
import AlumnoWelcome from "@/components/alumno/AlumnoWelcome.vue";
import AlumnoPerfil from "@/components/alumno/AlumnoPerfil.vue";
import AlumnoCursadas from "@/components/alumno/AlumnoCursadas.vue";

import Servicios from "@/components/Servicios.vue";
import Contactanos from "@/components/Contacto.vue";
// import { LayoutPlugin } from "bootstrap-vue";




Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/noticias",
    name: "Noticias",
    component: Noticias,
    meta: {
      requiresAuth: false,
    },
   },
   {
    path: "/noticia/:id",
    name: "Noticia",
    component: Noticia,
    meta: {
      requiresAuth: false,
    },
   },
   {
    path: "/servicios",
    name: "Servicios",
    component: Servicios,
   },
   {
    path: "/contactanos",
    name: "Contactanos",
    component: Contactanos,
   },
  {
    path: "/nosotros",
    name: "Nosotros",
    component: Nosotros,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/change-password/:token",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      requiresAuth: false,
      noHeader: true
    },
   },
   {
    path: "/verify-email",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: {
      requiresAuth: false,
      noHeader: true
    },
   },
  {
    path: "/courses",
    name: "Courses",
    component: Courses,
    meta: {
      requiresAuth: false,
    },
   },
   {
    path: "/courses/:id",
    name: "Courses",
    component: Courses,
    meta: {
      requiresAuth: false,
    },
   },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      // requiresAuth: true,
      noHeader: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      cks.clearCookies()
      next();
    },
    meta: {
      noHeader: true
    }
  },
  {
    path: "/institutions",
    name: "Institutions",
    component: Institutions,
  },
  {
    path: "/enterprises",
    name: "Enterprises",
    component: Enterprises,
  },
  {
    path: "/element/:id",
    name: "FooterElement",
    component: FooterElement,
  },
  {
    path: "/cursos",
    name: "Cursos",
    component: Cursos,
  },
  {
    path: '/admin',
    component: Admin,
    requiresAuth: true,
    requiresAuthAdmin: true,
    children: [
      {
        path: '',
        name: 'AdminWelcome',
        component: AdminWelcome,
        meta: {
          requiresAuthAdmin: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/admin/alumnos',
        name: 'AdminAlumnos',
        component: AdminAlumnos,
        meta: {
          requiresAuthAdmin: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/admin/cursadas',
        name: 'AdminCursadas',
        component: AdminCursadas,
        meta: {
          requiresAuthAdmin: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/admin/inscriptions',
        name: 'AdminInscriptions',
        component: AdminInscriptions,
        meta: {
          requiresAuthAdmin: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/admin/pagos',
        name: 'AdminPagos',
        component: AdminPagos,
        meta: {
          requiresAuthAdmin: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/admin/setting',
        name: 'AdminSettings',
        component: AdminSettings,
        meta: {
          requiresAuthAdmin: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/admin/notice',
        name: 'AdminNotice',
        component: AdminNotice,
        meta: {
          requiresAuthAdmin: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/admin/perfil',
        name: 'AdminPerfil',
        component: AdminPerfil,
        meta: {
          requiresAuthAdmin: true,
          requiresAuth: true,
          noHeader: true
         }
      },
    ]
  },
  {
    path: '/teacher',
    component: Teacher,
    requiresAuth: true,
    requiresAuthProfesor: true,
    children: [
      {
        path: '',
        name: 'TeacherWelcome',
        component: TeacherWelcome,
        meta: {
          requiresAuthATeacher: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/teacher/perfil',
        name: 'TeacherPerfil',
        component: TeacherPerfil,
        meta: {
          requiresAuthProfesor: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/teacher/courses',
        name: 'TeacherCourses',
        component: TeacherCourses,
        meta: {
          requiresAuthProfesor: true,
          requiresAuth: true,
          noHeader: true
         }
      },
    ]
  },
  {
    path: '/alumno',
    component: Alumno,

    children: [
      {
        path: '',
        name: 'AlumnoWelcome',
        component: AlumnoWelcome,
        meta: {
          requiresAuthAlumno: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/alumno/perfil',
        name: 'AlumnoPerfil',
        component: AlumnoPerfil,
        meta: {
          requiresAuthAlumno: true,
          requiresAuth: true,
          noHeader: true
         }
      },
      {
        path: '/alumno/cursadas',
        name: 'AlumnoCursadas',
        component: AlumnoCursadas,
        meta: {
          requiresAuthAlumno: true,
          requiresAuth: true,
          noHeader: true
         }
      },
    ]
  },
  {
    path: '*', // fallback
    redirect: '/'
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some(record => record.meta.requiresAuth)
  const authRequiredAdmin = to.matched.some(record => record.meta.requiresAuthAdmin)
  const isLogged = cks.isLogged()
  const admin = cks.getUser()
  const role = cks.getRole()

  if (authRequired && !isLogged) {
    next({ name: 'Login' })
  } else if (to.name === 'Login' && isLogged) {
      if( role == 'Admin' ){
        next({ name: 'AdminWelcome' })
      } else {
        if(role == 'Profesor'){
          next({ name: 'TeacherWelcome' })
        } else {
          if(role == 'Alumno'){
            next({ name: 'AlumnoWelcome' })
          } else {
            next({ name: 'Home' })
        }
      }
    }
  } else if (to.matched.some(record => record.meta.requiresAuthAdmin)) {
     if (role !== 'Admin') {
      next({ name: 'Home'  })
      }
    next()
  } else if (to.matched.some(record => record.meta.requiresAuthAlumno)) {
    if (role !== 'Alumno') {
     next({ name: 'Home'  })
     }
   next()
  } else if (to.matched.some(record => record.meta.requiresAuthTeacher)) {
    if (role !== 'Profesor') {
    next({ name: 'Home'  })
    }
  next()
  } else {
    next()
  }


//   if (authRequired && !isLogged) {
//     next({ name: 'Login' })
//   } else if (to.name === 'Login' && isLogged) {
//       if(admin.roles.includes('Admin')){
//         next({ name: 'AdminWelcome' })
//       } else {
//         if(admin.roles.includes('Alumno')){
//           next({ name: 'AlumnoWelcome' })
//         } else {
//           next({ name: 'Home' })
//       }
//     }
//   } else if (to.matched.some(record => record.meta.requiresAuthAdmin)) {
//      if (!admin.roles.includes('Admin')) {
//       next({ name: 'Home'  })
//       }
//     next()
//   } else if (to.matched.some(record => record.meta.requiresAuthAlumno)) {
//     if (!admin.roles.includes('Alumno')) {
//      next({ name: 'Home'  })
//      }
//    next()
//  } else {
//     next()
//   }
})


export default router;
