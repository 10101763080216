<template>
  <v-data-table
    class="elevation-1"
    :expanded.sync="expanded"
    :footer-props="{itemsPerPageText: 'Filas por pagina:'}"
    :headers="headers"
    item-key="id"
    :items="this.inscriptions.filter(filtrado => filtrado.cursada.id === this.cursada)"
    :loading="loadingStatus"
    loading-text="Cargando...por favor aguarde"
    :search="search"
    show-expand
    :single-expand="singleExpand">
    <template v-slot:top>
      <v-toolbar
        flat
        height="150px">
        <v-row class="flex flex-space-between">
          <v-col
            cols="12"
            lg="6">
            <v-toolbar-title>Lista de Inscripciones del {{ new Date().getFullYear() }}</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical></v-divider>
            <v-spacer></v-spacer>
          </v-col>
          <v-col
            cols="12"
            lg="6">
            <v-select
              v-model="cursada"
              autocomplete
              bottom
              item-text="courses.name"
              item-value="id"
              :items="cursadas.filter(courses => courses.lectivo.ano === new Date().toISOString().substr(0, 4))"
              label="Elige una cursada"
              :rules="[v => !!v || 'La cursada es requerido']"
              style="width:300px;"></v-select>
            <v-dialog
              v-model="dialog"
              class="text-left"
              max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text
                  class="mt-7">
                  <v-form
                    ref="courseForm"
                    v-model="valid"
                    lazy-validation>
                    <v-autocomplete
                      v-model="editedItem.user_id"
                      :filter="customFilter"
                      item-text="name"
                      item-value="id"
                      :items="users"
                      label="Elige al usuario"
                      :rules="[v => !!v || 'El usuario es requerido']"></v-autocomplete>

                    <v-select
                      v-model="editedItem.cursada_id"
                      autocomplete
                      bottom
                      item-text="courses.name"
                      item-value="id"
                      :items="cursadasCurrent"
                      label="Elige la cursada"
                      :rules="[v => !!v || 'La cursada es requerida']"></v-select>
                    <v-text-field
                      v-model="editedItem.becado"
                      label="Becado (1.00 = 0% - 0.95 = 5%)"
                      prepend-icon="mdi-percent"
                      required
                      :rules="becadoRules"></v-text-field>

                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn
                    v-if="editedIndex !== -1"
                    color="blue darken-1"
                    text
                    @click="save">
                    Guardar
                  </v-btn>
                  <v-btn
                    v-else
                    class="mr-4"
                    color="success"
                    :disabled="!valid"
                    :loading="loadingStatus"
                    @click="validate()">
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class="mr-2"
        small
        @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)">
        mdi-delete
      </v-icon>

    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="d-flex justify-space-around my-4">
          <v-data-table
            :headers="headersNotes"
            :items="item.notes">
          </v-data-table>
        </div>

      </td>
    </template>
    <!-- <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="categories">
        Reset
      </v-btn>
    </template> -->
    <template slot="no-data">
      <p>Por el momento no hay inscriptos a esta cursada</p>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    data: () => ({
      cursada: '',
      filterInscriptions: [],
      valid: true,
      expanded: [],
      singleExpand: true,
      search: '',
      date: null,
      menu: false,
      dialog: false,
      dialogDelete: false,
      headersNotes: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Cmpron Lectora', value: 'lectora' },
        { text: 'Cmpron Auditiva', value: 'auditiva' },
        { text: 'Prod. Escrita', value: 'escrita' },
        { text: 'Prod. Oral', value: 'oral' },
        { text: 'UOE', value: 'usenglish' },
      ],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Alumnno', value: 'user.name' },
        { text: 'Email', value: 'user.email' },
        { text: 'Acciones', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],

      editedIndex: -1,
      editedItem: {
        id: '',
        cursada_id:'',
        cursada: {
          id:'',
          price:'',
        },
        user_id:'',
        user: {
          id:'',
          name:'',
        },
        becado:'',
      },
      defaultItem: {
        id: '',
        cursada_id:'',
        cursada: {
          id:'',
          price:'',
        },
        user_id:'',
        user: {
          id:'',
          name:'',
        },
        becado:'',
      },
      user: {},
      becadoRules:[
        v => !!v || 'El precio es requerido',
        v => /^(0+\.[0-9][0-9]|1+\.[0][0])?$/g.test(v) || 'No tiene el formato necesario'
      ],
      nameRules: [
        value => !!value || 'Campo requerido',
        value => (value && value.length >= 10) || 'Mínimo 10 caracter',
        value => (value && value.length <= 40) || 'Máximo 40 caracter',
      ],
    }),

    computed: {
      users() {
        return this.$store.getters.getUsers
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
      inscriptions() {
        return this.$store.getters.getInscriptions
      },
      cursadas() {
        return this.$store.getters.getCursadas
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Inscripción' : 'Editar Inscipción'
      },
      cursadasCurrent() {
        let current = this.cursadas.filter(courses => courses.lectivo.ano === new Date().toISOString().substr(0, 4) );
        return current;
      },

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.$store.dispatch('fetchUsers'),
      this.$store.dispatch('fetchInscriptions')
      this.$store.dispatch('fetchCursadas')
    },

    methods: {
      customFilter (item, queryText, itemText) {
        const textOne = item.name.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1
      },
      editItem (item) {
        this.editedIndex = this.inscriptions.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      async deleteItem(item) {
        let res = await this.$confirm('¿Quiere eliminar esta inscripción?', { title: 'Inscripción' })
        if (res) {
          this.editedItem = Object.assign({}, item)
          this.$store.dispatch('deleteInscription', this.editedItem)
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          this.$store.dispatch('updateInscription', this.editedItem)
        }
        this.close()

      },
      //validar formulario
      validate () {
        if(this.$refs.courseForm.validate()) {
          this.$store.dispatch('createCourse', this.editedItem)
          this.$refs.courseForm.reset()
          this.close()
        }
      },
    },
  }
</script>

<style scoped>
  .v-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }
</style>>

