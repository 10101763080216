<template>
  <v-container
    fill-height
    fluid
    text-center>
    <v-row
      align="center"
      justify="center">
      <img
        alt=""
        class="mt-6"
        justify-center
        src="../../assets/logo.svg"
        width="50%">
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({

    }),
    computed: {
      // user() {
      //   return this.$store.getters.getUser
      // }
    }
  }
</script>

<style>

</style>