<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="note">
      <!-- editar comprension lectora -->
      <template v-slot:item.lectora="props">
        <v-edit-dialog
          large
          persistent
          :return-value.sync="props.item.lectora">
          {{ props.item.lectora }}
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Cmpron. Lectora:
            </div>
            <v-text-field
              v-model="props.item.lectora"
              counter
              label="Editar"
              :rules="[max10chars]"
              single-line></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <!-- editar comprension auditiva -->
      <template v-slot:item.auditiva="props">
        <v-edit-dialog
          large
          persistent
          :return-value.sync="props.item.auditiva">
          <div>{{ props.item.auditiva }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Cmpron Auditíva:
            </div>
            <v-text-field
              v-model="props.item.auditiva"
              autofocus
              counter
              label="Editar"
              :rules="[max10chars]"
              single-line></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <!-- editar producción escrita -->
      <template v-slot:item.escrita="props">
        <v-edit-dialog
          large
          persistent
          :return-value.sync="props.item.escrita">
          <div>{{ props.item.escrita }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Prod. Escrita:
            </div>
            <v-text-field
              v-model="props.item.escrita"
              autofocus
              counter
              label="Editar"
              :rules="[max10chars]"
              single-line></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <!-- producción oral -->
      <template v-slot:item.oral="props">
        <v-edit-dialog
          large
          persistent
          :return-value.sync="props.item.oral">
          <div>{{ props.item.oral }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Prod. Oral:
            </div>
            <v-text-field
              v-model="props.item.oral"
              autofocus
              counter
              label="Editar"
              :rules="[max10chars]"
              single-line></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <!-- editar use of english -->
      <template v-slot:item.usenglish="props">
        <v-edit-dialog
          large
          persistent
          :return-value.sync="props.item.usenglish">
          <div>{{ props.item.usenglish }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Uso del Ingles:
            </div>
            <v-text-field
              v-model="props.item.usenglish"
              autofocus
              counter
              label="Editar"
              :rules="[max10chars]"
              single-line></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          small
          @click="saveAll(item)">
          mdi-content-save-all
        </v-icon>

      </template>
    </v-data-table>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="3000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    props: {
      note: {
        type: Array,
        default: () => []
      },
    },
    data () {
      return {
        snack: false,
        snackColor: '',
        snackText: '',
        max10chars: v => v <= 100 || 'número no permitido (10-100)!',
        pagination: {},
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Cmpron Lectora', value: 'lectora' },
          { text: 'Cmpron Auditiva', value: 'auditiva' },
          { text: 'Prod. Escrita', value: 'escrita' },
          { text: 'Prod. Oral', value: 'oral' },
          { text: 'UOE', value: 'usenglish' },
          { text: 'Acciones', value: 'actions', sortable: false },
        ],
      }
    },

    methods: {
      saveAll(item) {
        this.$store.dispatch('updateNotes', item)
      },
      save () {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      },
      cancel () {
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Canceled'
      },
      open () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = 'Dialog opened'
      },
    }
  }
</script>

<style>

</style>