<template>
  <v-container>
    <v-main class="full-height-custom">
      <div
        v-if="loadingStatus">
        <scale-loader
          class="pt-15"
          color="#000000"
          size="45"></scale-loader>
      </div>
      <v-col
        v-if="category()"
        cols="12"
        lg="12"
        md="12"
        sm="12"
        xl="12"
        xs="12">
        <h5
          class="text-center mx-10 my-10">Listado de cursos de la categoria {{ listCourses.category.name }}</h5>
        <p class="text-center my-10">{{listCourses.category.description}}</p>
        <v-row justify="center">
          <v-col
            v-for="c in courses()"
            :key="c.id"
            cols="12"
            lg="5"
            md="12"
            sm="12"
            xl="5"
            xs="12">
            <v-hover
              v-slot="{ hover }"
              open-delay="200">
              <v-card
                class="gray mb-5 text-center"
                :class="{ 'on-hover': hover }"
                :elevation="hover ? 16 : 2"
                min-height="300">
                <h2 class="justify-center py-10">{{c.name}}</h2>
                <div
                  class="contenedor-img ">
                  <v-card-subtitle>{{c.description }}</v-card-subtitle>
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-main>
  </v-container>
</template>

<script>
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  export default {
    components: {
      ScaleLoader,
    },
    data () {
      return {
        id: this.$route.params.id || undefined
      }
    },
    methods: {
      courses() {
        return this.listCourses.courses
      },
      category() {
        return this.listCourses.category
      },
    },
    computed: {
      listCourses() {
        return this.$store.getters.getCourses
      },
      loadingStatus() {
        return this.$store.getters.getLoadingStatus
      },
    },
    created() {
      this.$store.dispatch('fetchListCourses', this.id)
    }
  }
</script>

<style>
  .full-height-custom {
    min-height: calc(100vh - 340px);
  }
</style>